import React from "react";
import styled from "styled-components";

function TextareaField({ value, title, onChange, disabled }) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleChange = React.useCallback((event) => {
    if (disabled) return;
    onChange(event);
  }, [disabled, onChange]);

  const handleFocus = React.useCallback(() => setIsExpanded(true), []);
  const handleBlur = React.useCallback(() => setIsExpanded(false), []);

  return (
    <Container focused={isExpanded}>
      <Textarea
        disabledText={disabled}
        rows={isExpanded ? 5 : 1} 
        onFocus={handleFocus} 
        onBlur={handleBlur} 
        value={value} 
        onChange={handleChange} 
      />
      <Fieldset>
        <Legend disabled={disabled}>{title}</Legend>
      </Fieldset>
    </Container>
  );
}

const Container = styled("div")`
  padding: 0.5em 0.25em;
  position: relative;
  display: flex;

  &:hover fieldset {
    border-color: #ff9800;
  }
    
  ${({ focused }) => focused && `
    fieldset { border-color: #2196f3 !important; }
    legend { color: #0071e3; }
  `}
`;

const Textarea = styled("textarea")`
  width: 100%;
  resize: none;
  border: 0;
  margin: 8px;
  z-index: 2;
  background: transparent;
  outline: none;
  color: ${({ disabledText }) => disabledText ? "rgba(0, 0, 0, 0.55)" : "rgba(0, 0, 0, 0.87)"};
`;

const Fieldset = styled("fieldset")`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const Legend = styled("legend")`
  padding: 0 4px;
  font-size: 0.85em;
  color: ${({ disabled }) => disabled ? "rgba(0, 0, 0, 0.55)" : "rgba(0, 0, 0, 0.6)"};
`;

export default React.memo(TextareaField);
