import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";

import BitArray from "shared/utils/BitArray";
import TableEditor, { DUPLICATE_INDENTIFICATOR, TOOLBAR_DISABLE_CASES } from "shared/ui/TableEditor";
import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";
import { Icon, TooltipR } from "shared/ui/ToolBar";
import { ContourBackdrop } from "shared/ui";
import CustomCheckbox from "shared/ui/CustomCheckbox";

import ItemDialog from "components/itemDialog";

import FileUploadForm from "../FileUploadForm";

import ViewRowCard from "./ViewRowCard";

import CustomHeader from "./Modals/CustomHeader";
import ShowData from "./Modals/ShowData";
import Calculation from "./Modals/Calculation";
import Components from "./Modals/Components";
import ShowDataDownload from "./Modals/ShowDataDownload";
import RegistryDialog from "./RegistryDialog";
import ShowDataComp from "./Modals/ShowDataComp";
import DownloadFile from "./Modals/DownloadFile";
import PdfDocLoader from "./Modals/PdfDocLoader";
import PdfRegistryLoader from "./Modals/PdfRegistryLoader";
import PdfSurveyLoader from "./Modals/PdfSurveyLoader";
import PdfDocRefLoader from "./Modals/PdfDocRefLoader";
import PdfDocRegLoader from "./Modals/PdfDocRegLoader";
import ExportIndicator from "./Modals/ExportIndicator";

import { getCreateModalTitle, getEditModalTitle, getPdfModalTitle, getViewModalTitle } from "./utils/modalTitle";
import { parseSettingsFilters } from "./utils/parseSettingsFilters";
import { isFilterColumn } from "./utils/isFilterColumn";

import { ReactComponent as ChevronDownIcon } from '@mdi/svg/svg/chevron-down.svg'

export function tableTypeByWidgetFieldType(type) {
  switch (type?.toLowerCase()) {
    case "reference": return "Dictionary";
    case "bi_project": return "IFrameLink";
    case "json_object_array": return "ObjectArray";
    case "file_link": return "Download";
    case "datetime": return "DateTime";
    case "checkbox": return "Boolean";
    case "svg_image": return "Image";
    case "image": return "Image";
    case "const": return "const";
    case "number":
    case "int": return "Number";
    case "date": return "Date";
    default: return "Memo"
  }
}

let tableId = 0;

const followRegistries = ["subindicator_no_statutory", "subindicator_statutory"]
const dependentRegistries = ["subindicator_3_no_statutory", "subindicator_3_statutory"]

function getDependentRegistry(code) {
  if (code === followRegistries[0]) return dependentRegistries[0];
  if (code === followRegistries[1]) return dependentRegistries[1];
  return null;
}

function getDependentRegistryErrorText(code) {
  if (code === dependentRegistries[0]) return "Выберите значение в таблице «Информация о типах данных с указанием иных сроков предоставления и иной разрезности данных»";
  if (code === dependentRegistries[1]) return "Выберите значение в таблице «Информация о сроках предоставления и разрезности данных»";
  return "";
}


function isFieldRequired(condition, data) {
  if (!condition) return false;
  if (typeof condition === "boolean") return condition;
  const [code, value] = condition.split("=");
  if (!value) return Boolean(+condition);
  return data?.[code] === value;
}

function withIndicatorFilters(registryCode) {
  return ["indicator", "indicator_np_fp", "indicator_of_stat"].includes(registryCode)
}

function isApprovedRow(row) {
  if (!row || !row.wf_status) return false;
  const statusValues = row.wf_status.split(".");
  return statusValues[1] === "1" || statusValues[3] === "1";
}

function isFixedRow(row) {
  if (!row || !row.wf_status) return false;
  const statusValues = row.wf_status.split(".");
  return statusValues[16] === "1" || statusValues[17] === "1";
}

function parseBoolean(value) {
  if (typeof value === "boolean") return value;
  if (value === 0 || value === 1) return Boolean(value);
  if (value === "0" || value === "1") return Boolean(+value);
  return value;
}

function defualtColumnSortByRegistry(registry, field) {
  if (registry.code === "upload_registry_np_fp" && field.code === "upload_registry_np_fp_grid_period") return "DESC";
  if (registry.code === "upload_registry_of_stat" && field.code === "upload_registry_of_stat_grid_period") return "DESC";
  if (registry.code === "document_registry_np_fp" && field.code === "document_registry_np_fp_grid_upload_registry_period") return "DESC";
  if (registry.code === "document_registry_of_stat" && field.code === "document_registry_of_stat_grid_upload_registry_period") return "DESC";
  if (registry.code === "data_registry_np_fp" && field.code === "data_registry_np_fp_grid_period") return "DESC";
  if (registry.code === "data_registry_of_stat" && field.code === "data_registry_of_stat_grid_period") return "DESC";
  if (registry.code === "calculation_registry" && field.code === "calculation_registry_grid_period_start") return "DESC";
  if (registry.code === "export_registry_np_fp" && field.code === "export_registry_np_fp_grid_period") return "DESC";
  if (registry.code === "export_registry_of_stat" && field.code === "export_registry_of_stat_grid_period") return "DESC";
  return "none";
}

function columnSortOrder(registry, field, settingsSortOrder, sortOrder) {
  if (Array.isArray(settingsSortOrder) && settingsSortOrder.length > 0) {
    const foundSort = sortOrder.find((sort) => sort[0] === field.source);
    return foundSort ? foundSort[1] : "none";
  }

  const sorting = defualtColumnSortByRegistry(registry, field);
  if (sorting !== "none") {
    const isSortingExist = sortOrder.find(([code]) => code === field.source);
    if (!isSortingExist) sortOrder.push([field.source, sorting]);
  }

  return sorting;
}

function checkVisibility(visibility, isVisible) {
  if (typeof visibility !== "string") return true;
  if (visibility === "0") return false;

  if (visibility.includes("|")) {
    let foundMismatch = true;
    visibility.split("| ").forEach(visibility => {
      const [code, value] = visibility.split("=");
      if (!code || !value) {
        foundMismatch = false;
        return;
      }

      if (!isVisible(code, value)) return;
      foundMismatch = false;
    })
    return !foundMismatch;
  }

  const divider = visibility.includes("&") ? "& " : visibility.includes(",") ? ", " : null;
  if (divider) {
    let foundMismatch = false;
    visibility.split(divider).forEach(visibility => {
      const [code, value] = visibility.split("=");
      if (!code || !value) {
        foundMismatch = true;
        return;
      }

      if (isVisible(code, value)) return;
      foundMismatch = true;
    })
    return !foundMismatch;
  }
  
  const [code, value] = visibility.split("=");
  if (!code || !value) return false;
  return isVisible(code, value);
}

function isNestingRegistry(registry) {
  if (registry.code === "stat_publications" || registry.code === "question_registry") return true;
  return registry.object_class === "ParentChildClassifier";
}

function getEmptySettingsObject() {
  return {
    filters: null,
    sort_order: null,
    area: {
      hidden: null,
      filters: null,
      defaults: null,
    }
  }
}

export const FILTERS_ARRAY = [{ code: "is_ind", name: "Показатель" }, { code: "is_comp", name: "Компонент" }];

function Registry({ filters, canEdit = true, apiPayload = {}, rowData, withoutParentCodeInNestedGrid, objectValue, widgetAction, singleRowSelect, onChange, subRegistry, columnStyles, withOperations = true, resetFilters, parentCode, parentRowField, parentRowCode, code, mainTable }) {
  const dispatch = useDispatch();
  const confirmationDialog = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const navigate = useNavigate();

  const api = useSelector(state => state.API);
  const userData = useSelector(state => state.userData);
  const current = useSelector((state) => state.current);
  const columnFilters = useSelector(state => state.columnFilters);
  const hiddenColumns = useSelector(state => state.hiddenColumns);

  const sortOrder = React.useRef([]);
  const createdIds = React.useRef([]);

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [registry, setRegistry] = React.useState(null);
  const [activeFilters, setActiveFilters] = React.useState(FILTERS_ARRAY);
  const [reloadHandler, setReloadHandler] = React.useState(null);
  const [changeRowsHandler, setRowsHandler] = React.useState(null);
  const [pushDataHandler, setPushDataHandler] = React.useState(null);
  const [removeDataHandler, setRemoveDataHandler] = React.useState(null);
  const [references, setReferences] = React.useState({});
  const [registryError, setRegistryError] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [uploadFormVisibility, setUploadFormVisibility] = React.useState(false);
  const [recalculateHandler, setRecalculateHandler] = React.useState();
  const [statGridRow, setStatGridRow] = React.useState([]);
  const [columnFilterError, setColumnFilterError] = React.useState(false);
  const [jumps, setJumps] = React.useState([]);
  const [allRegistries, setAllRegistries] = React.useState([]);
  const [settings, setSettings] = React.useState(null);
  const [filtersHandler, setFiltersHandler] = React.useState();
  const [internalLoading, setInternalLoading] = React.useState(false);

  const [clearFilters, setClearFilters] = React.useState(() => () => {});

  const initializeRegistry = React.useCallback(async () => {
    setRegistry(null);
    setRegistryError(false);
    const registry = await api.call("registries/registry", { code });
    if (typeof registry !== "object" || registry === null || registry?.error) return setRegistryError(true);
    const referenceKeys = Object.keys(registry.reference);
    const references = await Promise.all(referenceKeys.map((field) => api.call("registries/values", { code, field, action: widgetAction })));
    const referencesObject = Object.fromEntries(referenceKeys.map((key, index) => [key, Array.isArray(references[index]) ? references[index] : []]));

    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid");
    if (foundWidget && foundWidget.operation.find(({ code }) => code === "jump")) {
      const [jumps, all] = await Promise.all([
        api.call("registries/jumps"),
        api.call("registries/all")
      ]);
      if (Array.isArray(all)) setAllRegistries(all);
      if (Array.isArray(jumps)) setJumps(jumps.filter(({ registry_from }) => registry_from === code));
    }

    if (!subRegistry && !current?.current?.withoutLoadSettings) {
      const settings = await api.call("registries/settings/load", { code });
      if (settings?.area) setSettings(settings);
      if (Array.isArray(settings?.sort_order)) sortOrder.current = settings?.sort_order.map(([field, value]) => {
        if (typeof field !== "string") return [field, value];
        return [field.split(DUPLICATE_INDENTIFICATOR)[0], value]; 
      });
    }

    if (referencesObject.row_number) setStatGridRow(referencesObject.row_number);
    setReferences(referencesObject);
    setRegistry(registry);
  }, [api, code, subRegistry, widgetAction]);

  React.useEffect(() => initializeRegistry(), [initializeRegistry]);

  const clearWidgetSearchParams = React.useCallback(
    () => navigate(current?.current?.hideInParams ? process.env.PUBLIC_URL + "/" : `${process.env.PUBLIC_URL}/${params.registry}/`), 
    [current, navigate, params]
  );

  const getDashboardId = React.useCallback(async (row) => {
    const foundColumn = registry.widget[0]?.field_group[0]?.field?.find(({ field_type }) => field_type === "bi_project");
    let id = row[foundColumn?.source];

    if (!id) {
      const data = await api.send("registries/list", {
        page_size: 50,
        page_number: 0,
        sort_order: [],
        code: "dashboard_registry",
        filter: [[null, "indicator", "=", row.code, false, null, "AND"]],
      });
      if (Array.isArray(data) && data.length > 0) id = data[0].url
    }

    return id;
  }, [api, registry]);
  
  const handleDashboardClick = React.useCallback(async ({ id, closeModal }) => {
    if (!id) {
      dispatch({ type: "SET_API_ERROR", value: "Панель не найдена" })
      return;
    }

    closeModal?.();
    dispatch({ type: "SET_CURRENT", value: { current: { 
      id,
      properties: {title: "Панель", hint: null, icon: null},
      type: "dashboard"
    } } })
  }, [dispatch]);

  const handleRegistryDialogOpen = React.useCallback((code, title, filter) => {
    confirmationDialog.getConfirmation({
      content: (onOk, onClose) => <RegistryDialog 
        onClose={onClose} 
        filter={filter} 
        title={title} 
        code={code} 
      />,
      type: "Dialog",
    });
  }, [confirmationDialog]);

  const getCardWidgetFields = React.useCallback(({ filter, action, newData, foundWidget, subindicatorFilters, foundData, editMode, hideOperations }) => {
    const schema = { props: [] };
    const indicators = [];
    const baseValue = {};
    const throughoutFields = [];
    const visibleConditionFields = [];
    const editableConditionFields = ["is_comp", "is_ind"];

    const data = newData || foundData || {};

    foundWidget.field_group.forEach(group => {
      if (!Array.isArray(group.field)) return;
      const tab = {
        name: group.value,
        icon: null
      }

      const filteredFields = group.field.filter(field => {
        return checkVisibility(field.visibility, (code, value) => {
          if (!visibleConditionFields.includes(code)) visibleConditionFields.push(code);
          if (dependentRegistries.includes(registry?.code)) return columnFilters?.[registry.code]?.[code] === value;
          return parseBoolean(data?.[code]) === parseBoolean(value);
        })
      });

      const imageFields = [];
      const otherTypeFields = [];
      filteredFields.forEach((field) => {
        (field.field_type === "image" ? imageFields : otherTypeFields).push(field);
      })

      const isFieldEditable = (field) => {
        const condition = field.editable_condition;
        if (!condition) return !field.editable;
        if (condition === "0") return false;

        if (condition.includes("|")) {
          let foundMismatch = true;
          condition.split("| ").forEach(condition => {
            const [code, value] = condition.split("=");
            if(!editableConditionFields.includes(code)) editableConditionFields.push(code);
            if (code === "is_comp" && parseBoolean(data?.is_ind)) {
              foundMismatch = false;
              return;
            }
            if (!code || !value) {
              foundMismatch = false;
              return;
            }
            if (parseBoolean(data?.[code]) !== parseBoolean(value)) return;
            foundMismatch = false;
          })
          return foundMismatch;
        }

        const divider = condition.includes("&") ? "& " : condition.includes(",") ? ", " : null;
        if (divider) {
          let foundMismatch = false;
          condition.split(divider).forEach(condition => {
            const [code, value] = condition.split("=");
            if(!editableConditionFields.includes(code)) editableConditionFields.push(code);
            if (code === "is_comp" && parseBoolean(data?.is_ind)) {
              foundMismatch = true;
              return;
            }
            if (!code || !value) {
              foundMismatch = true;
              return;
            }
            if (parseBoolean(data?.[code]) === parseBoolean(value)) return;
            foundMismatch = true;
          })
    
          return !foundMismatch;
        }
        
        const [code, value] = condition.split("=");
        if (!code || !value) return false;
        if(!editableConditionFields.includes(code)) editableConditionFields.push(code);
        if (code === "is_comp" && parseBoolean(data?.is_ind)) return true;
        return parseBoolean(data?.[code]) !== parseBoolean(value);
      };

      const isFieldThroughout = (field) => {
        if (!field.throughout) return;
        throughoutFields.push(field.source);
        const value = data?.[field.source];
        if (!value) return () => {};
        return () => handleRegistryDialogOpen(
          field.source,
          field.value,
          [[null, "code", "=", value, false, null, "AND"]]
        )
      };

      const sources = [
        "regulation_doc_type",
        "regulation_number",
        "regulation_data",
        "regulation_organization"
      ];
      let index = 0;
      let multiField = false;

      const nonImageFields = otherTypeFields.map((field) => {
        const baseField = {
          value: field.source,
          label: field.value,
          tab,
          type: "text",
          data: { 
            throughout: isFieldThroughout(field),
            required: isFieldRequired(field.required, foundData), 
            disabled: isFieldEditable(field) 
          },
        };

        if (index === 4) multiField = true;
        if (field.source === sources[index]) {
          index++;
        } else {
          index = 0;
        }

        if (field.source === "phone") {
          baseField.type = "phone";
          return baseField;
        }

        switch (field.field_type) {
          case "reference": {
            baseField.type = "fSelect";
            baseField.data.onlyIdInValue = true;
            baseField.data.disableClearable = false;
            baseField.data.select = references[/* field.reference ||  */field.source]?.map(
              (reference) => ({ id: reference.code, isChild: !!reference.parent_code, name: field.source_field ? reference[field.source_field] : reference.value })
            ).filter(({ name }) => !!name);
            return baseField;
          }

          case "subref": {
            baseField.type = "Registry";
            baseField.data.code = field.source;
            baseField.data.fieldCode = field.code;
            baseField.data.action = action;
            baseField.data.rowData = foundData;
            baseField.data.parentRowCode = foundData?.indicator || foundData?.indicator_code || foundData?.code;
            baseField.data.parentRowField = field.source_field;
            baseField.data.editable = editMode /* && isFieldEditable(field) */;
            baseField.data.styles = { maxWidth: 135 };
            baseField.data.withOperations = hideOperations ? false : editMode;
            baseField.data.singleRowSelect = field.source === "subindicator_no_statutory";
            baseField.data.subRegistry = true;
            baseField.data.filters = [
              [null, field.source_field || "parent_code", "=", foundData?.indicator_code || foundData?.indicator || foundData?.code, false, null, "AND"],
              ...(subindicatorFilters || (foundData ? [[null, "version", "=", foundData.version, true, null, "AND"]] : [])),
            ];
            indicators.push(field.source);
            return baseField;
          }

          case "datetime":
          case "date": {
            baseField.type = "Date";
            baseField.data.autoSetValue = false;
            return baseField;
          }

          case "textarea": {
            baseField.type = "textarea";
            return baseField;
          }

          case "json_string_array": {
            baseField.type = "multiSelect";
            return baseField;
          }

          case "checkbox": {
            baseField.type = "boolean";
            baseValue[field.source] = false;
            return baseField;
          }

          case "file_link": {
            baseField.type = "download";
            baseField.data.upload = editMode && field.editable;
            baseField.data.uploadProps = {
              token: api.token,
              registry: registry.code,
              code: foundData?.code,
              field: field.source,
            };
            baseField.data.hideValue = true;
            return baseField;
          }
        
          default: return baseField;
        }
      });

      const fields = [];
      imageFields.forEach((field) => {
        fields.push({
          value: field.source,
          label: field.value,
          tab,
          type: "imageBlock",
          data: {
            disabled: true,
            withProps: nonImageFields.splice(0, 3),
          },
        });
      });
      fields.push(...nonImageFields);

      if (multiField) {
        const foundIndex = fields.findIndex(({ value }) => sources.includes(value));
        const data = {
          value: "",
          label: "",
          tab,
          type: "multiField",
          disabled: !editMode,
          fields: fields.slice(foundIndex, foundIndex + sources.length)
        };
        fields.splice(foundIndex, sources.length, data);
      }

      if (fields.length === 0) return;
      schema.props.push({ tab }, ...fields);
    });

    return { schema, visibleConditionFields, editableConditionFields, throughoutFields, indicators, baseValue };
  }, [api, registry, activeFilters, columnFilters, references]);

  React.useEffect(() => {
    if (!registry || !mainTable || !reloadHandler) return;
    const cardCode = params.widget;
    const rowCode = params.row;
    const version = params.version;
    if (!cardCode || !rowCode) {
      clearWidgetSearchParams();
      return;
    }
    (async () => {
      const foundWidget = registry.widget.find(({ code }) => code === cardCode);
      if (!foundWidget || foundWidget.widget_type !== "card") return clearWidgetSearchParams();

      const data = await api.send("registries/list", {
        page_size: 50,
        page_number: 0,
        sort_order: [],
        code,
        filter: [[null, "code", "=", rowCode, false, null, "AND"]],
      });
      if (!Array.isArray(data)) return clearWidgetSearchParams();

      const foundRow = version ? data.find((row) => String(row.version) === version) : data[0];
      if (!foundRow) return clearWidgetSearchParams();

      const dashboardOperation = foundWidget.operation?.find(({ action }) => action === "dashboard");
      let dashboardLink = null;
      if (dashboardOperation) {
        await confirmationDialog.getConfirmation({
          content: (onOk, onClose) => {
            (async function() {
              dashboardLink = await getDashboardId(data[0])
              onClose();
            })();
            return <ContourBackdrop />
          },
          type: "Dialog",
        });
      }
  
      const { schema, indicators } = getCardWidgetFields({ foundWidget, action: "card", foundData: data[0], editMode: false });
      const actions = foundWidget.operation?.map(operation => ({
        onClick: ({ closeModal }) => {
          if (operation.action !== "dashboard") return;
          handleDashboardClick({ id: dashboardLink, closeModal });
        },
        label: operation.value,
        hint: operation.hint,
        disabled: operation.action === "dashboard" ? !dashboardLink : false,
      }));

      const response = await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <ViewRowCard
            title={getViewModalTitle(foundRow)}
            schema={schema}
            withIndicatorFilters={withIndicatorFilters(registry?.code)}
            updateSchema={(filter, newData) => getCardWidgetFields({ filter, newData, action: "card", foundWidget, foundData: data[0], editMode: false })}
            maxWidth={1000}
            minHeight="calc(100vh - 40px)"
            withConfirmation
            customActions={actions}
            withAction={["Закрыть", null]}
            type=""
            prop={{ current: { properties: data[0] }}}
            onOk={onOk}
            onClose={onClose}
          />
        ),
        type: "Dialog",
      });

      clearWidgetSearchParams();
      if (!response) return;
      indicators.forEach(code => {
        const data = response.v[code + "-table"];
        if (!data) return;
        api.call("registries/save", { code, data });
      })
      reloadHandler()
    })();
  }, [registry, reloadHandler]);

  React.useEffect(() => {
    if (!registry) return null;
    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid" || widget.widget_type === "stat_grid");
    if (!foundWidget) {
      setRegistryError(true);
      return null;
    }

    const fieldGroup = Array.isArray(foundWidget.field_group) ? foundWidget.field_group[0] : foundWidget.field_group; 
    if (!fieldGroup) {
      setRegistryError(true)
      return null;
    };

    const isStatGrid = foundWidget.widget_type === "stat_grid";
    const allColumns = fieldGroup.field.map((field) => {
      const isFilter = isFilterColumn(field, isStatGrid, settings?.area?.filters);
      const isReference = field.field_type === "reference";
      const isTags = field.field_type === "json_string_array";
      const isFixedCol = field.area === "fixed" || field.source === "ext_code" || field.source === "value" || field.is_fixed;

      const styles = columnStyles || {};
      if (field.max_width) styles.maxWidth = field.max_width;

      const fieldType = tableTypeByWidgetFieldType(field.field_type);
      const { filter, textFilter } = parseSettingsFilters(settings?.filters, field.code, fieldType);

      return {
        id: field.code,
        styles,
        field,
        properties: {
          title: field.icon ? "" : field.value,
          name: field.source,
          axis: isFilter ? "filter" : isFixedCol ? "fixed" : "data",
          position: 0,
          sortOrder: columnSortOrder(registry, field, settings?.sort_order, sortOrder.current),
          filter,
          textFilter,
          source_field: field.source_field,
          field_type: field.field_type,
          throughout: field.throughout,
          hidden: field.area === "hidden" || field.visibility === "0" || settings?.area.hidden?.includes(field.code),
          type: fieldType,
          isEditable: canEdit && field.editable,
          editable_condition: field.editable_condition,
          additionalContent: isReference 
            ? () => <div>{Icon("action", ChevronDownIcon)}</div> 
            : field.icon
              ? () => (
                <TooltipR text={field.source === "is_comp" ? "Компонент" : field.source === "is_ind" ? "Показатель" : ""}>
                  <HeaderIconWrapper dangerouslySetInnerHTML={{ __html: field.icon }} />
                </TooltipR>
              ) : null,
          customHeader: (props) => <CustomHeader {...props} references={references} />,
          customCellRenderer: (isReference || isTags || isStatGrid) ? (value, _, rowData, column, setBackground) => {
            if (isStatGrid && column.data?.properties?.name === "fact") {
              const foundRow = statGridRow.find(({ code }) => code === rowData.row_number);
              if (!foundRow) return value;
              const foundCell = foundWidget.cell?.find(({ coordinate }) => coordinate?.includes("row=") && coordinate.split("row=")[1] === foundRow.row_number);
              if (!foundCell) return value;
              if (!!foundCell.formula) setBackground("rgba(0, 0, 0, 0.04)")
              return <div style={{ minHeight: 30 }} title={foundCell.hint}>{value}</div>
            }

            if (value === null || value === undefined) return null;

            if (isReference) {
              const source = /* column.data?.field.reference ||  */column.data?.properties?.name?.split(DUPLICATE_INDENTIFICATOR)[0];

              if (field.source_field_type === "status" && references[source]) {
                const foundReference = references[source].find(({ code }) => code === value);
                return (
                  <TooltipR text={field.source_field ? foundReference?.[field.source_field] || null : foundReference?.value || value}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: 40 }}>
                      <div style={{ marginTop: 1, width: 12, height: 12, borderRadius: "50%", background: foundReference?.color }} />
                    </div>
                  </TooltipR>
                )
              }

              if (source && references[source]) {
                const foundReference = references[source].find(({ code }) => code === value);
                return field.source_field ? foundReference?.[field.source_field] || null : foundReference?.value || value;
              }
            }

            if (field.field_type === "json_string_array") {
              try {
                let array = null;
                try {
                  const parsedArray = JSON.parse(value);
                  if (Array.isArray(parsedArray)) array = parsedArray;
                } catch (error) {}
                if (!array) return null;
                return (
                  <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                    {array.map(tag => (
                      <CustomChip
                        style={{ backgroundColor: "transparent" }}
                        variant="outlined"
                        size="small"
                        label={tag}
                      />
                    ))}
                  </div>
                );
              } catch (error) {
                return value;
              }
            }

            return value;
          } : null
        }
      }
    });

    const columnsOrder = settings?.area?.defaults;
    if (!columnsOrder) {
      setColumns(allColumns);
      return;
    }

    const columns = [];
    columnsOrder.forEach(code => {
      const foundColumnIndex = allColumns.findIndex(({ id }) => id === code);
      if (foundColumnIndex === -1) return;
      const column = allColumns.splice(foundColumnIndex, 1)[0];
      columns.push(column);
    });
    columns.push(...allColumns);

    setColumns(columns);
  }, [registry, references]); 

  React.useEffect(() => {
    setColumnFilterError(false);
    if (!registry || !dependentRegistries.includes(code)) return;
    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid");
    if (!foundWidget) return;
    const fieldGroup = Array.isArray(foundWidget.field_group) ? foundWidget.field_group[0] : foundWidget.field_group; 
    if (!fieldGroup || !columnFilters || !columnFilters[code]) return;

    const filters = columnFilters[code];
    const isFieldVisible = (field) => {
      return checkVisibility(field.visibility, (code, value) => {
        if (!filters[code]) {
          setColumnFilterError(true);
          return false;
        }
        return filters[code] === value;
      });
    }

    setColumns(columns => (
      columns.map(column => {
        const field = fieldGroup.field.find(field => field.code === column.id);
        if (!field) return column;
        column.properties.hidden = !isFieldVisible(field);
        return column;
      })
    ))
  }, [columnFilters, code, registry]);
  
  const handleColumnsShow = React.useCallback(({ dim, hoverId, pre }) => {
    const newColumns = [...columns];

    if (hoverId) {
      const foundDropIndex = newColumns.findIndex(({ id }) => id === hoverId);
      if (foundDropIndex === -1) return;
      newColumns.splice(pre ? foundDropIndex : foundDropIndex + 1, 0, dim);
    } else {
      newColumns.push(dim);
    }

    setColumns(newColumns);

    dispatch({ 
      type: "SET_HIDDEN_COLUMN_TO_UPDATE", 
      value: dim.id
    })
  }, [columns, dispatch]);

  const getSelected = React.useCallback((id, ignoreAllAndNone = false, ndx = 3) => {
    const dim = columns.find(i => i.id === id)
    const f = dim.properties.filter || [];

    const result = dim.dict
    if (!Array.isArray(result)) return []

    const ba = new BitArray(result.length)
    ba.values = f

    if (dim.properties.inverse !== true)
        ba.not()

    const ret = []

    // Если выбрано все или ничего
    if (ba.count() === 0) {
        if (ignoreAllAndNone)
            return []
        result?.forEach((i) => { ret.push(i[ndx]) })
        return ret
    }

    function findSelected(dict) {
      if (ba.get(dict.index)) ret.push(dict.id)
      if (!Array.isArray(dict.childrens)) return
      dict.childrens.forEach(findSelected);
    }

    result?.forEach(findSelected)
    return ret;
  }, [columns]);

  const handleOperationClick = React.useCallback(async ({action, code, target, value, closeModal, reload, tableData, rows}) => {
    if (action === "associator_data") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      const dataCode = foundData.indicator_code || foundData.indicator || foundData.code;
      dispatch({ type: "SET_CURRENT", value: { current: { 
        id: dataCode + "-" + registry.code + "-" + code,
        hideInParams: true,
        withoutLoadSettings: true,
        registry: registry.code?.startsWith("data_registry") ? registry.code : "indicator_np_fp",
        apiPayload: {
          associator_filter: {
            associator_code: "associator_comp",
            subject_field: code === "has_comp" ? "code_comp" : "indicator",
            object_code: dataCode,
            object_field: code === "has_comp" ? "indicator" : "code_comp",
            field_code: registry.code?.startsWith("data_registry") ? "indicator_code" : "code",
          } 
        },
        properties: {title: code === "has_comp" ? `Компоненты (${foundData.ext_code})` : `Показатели (${foundData.ext_code})`, hint: null, icon: null},
        type: "registry"
      } } })
      return;
    }

    if (action === "sign") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      
      let isNullFieldsFound = false;
      await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => {
          (async function() {
            const result = await api.send("registries/data/list", { registry: registry.code, code: foundData.code });

            if (Array.isArray(result)) {
              if (result.length === 0) isNullFieldsFound = true;
              result.forEach((item) => {
                Object.values(item).forEach((value) => {
                  if (value !== null) return;
                  isNullFieldsFound = true;
                });
              });
            }

            onClose();
          })();
          return <ContourBackdrop />;
        },
        type: "Dialog",
      });

      const res = await confirmationDialog.getConfirmation({
        title: "Отправка на согласование",
        text: <span>Вы уверены, что хотите отправить на согласование{isNullFieldsFound ? <> данные, содержащие <span style={{ fontWeight: "bold", textDecoration: "underline" }}>пустые</span> значения?</> : "?"}</span>,
        width: "sm",
        type: "Warning",
        redIcon: isNullFieldsFound,
      })
      if (!res) return;

      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => {
          (async function() {
            const result = await api.send("registries/sign", { registry: registry.code, code: foundData.code });
            if (!result || result.error || result.message || result.ok === false) {
              enqueueSnackbar(result?.message || "Ошибка запроса", { variant: "error" });
              reload(rows);
              onClose();
              return;
            }

            enqueueSnackbar(result?.message || "Выполнено", { variant: "success" });
            reload(rows);
            onClose();
            window.open(process.env.REACT_APP_OUZD_UI_URL);
          })();
          return <ContourBackdrop />;
        },
        type: "Dialog",
      });
      return;
    }
    
    if (action === "export") {
      if (rows.length !== 1) return;

      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData?.code) return;

      const result = await api.send("export/run", { code: foundData.code });
      if (!result || result?.error || !result?.ok) {
        enqueueSnackbar(result?.message || "Ошибка запроса", { variant: "error" });
        return;
      }
      
      enqueueSnackbar(result?.message || "Выполнено", { variant: "success" });
      return;
    }

    if (action === "showdatacalc") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      dispatch({ type: "SET_CURRENT", value: { current: { 
        id: "data_registry_np_fp-" + foundData.code,
        hideInParams: true,
        registry: "data_registry_np_fp",
        withoutLoadSettings: true,
        apiPayload: {
          associator_filter: {
            associator_code: "associator_calc",
            subject_field: "data_registry_code",
            object_code: foundData.code,
            object_field: "calculation_registry_code",
            field_code: "code",
          } 
        },
        properties: { title: `Наборы данных (${foundData.ext_code})`, hint: null, icon: null },
        type: "registry"
      } } })
      return;
    }

    if (action === "fix" || action === "fix_rosstat") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      const res = await confirmationDialog.getConfirmation({
        title: value,
        text: "Вы уверены, что хотите зафиксировать выбранную строку?",
        width: "sm",
        type: "Warning",
      })
      if (!res) return;
      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => {
          (async function() {
            const res = await api.send("registries/fixate", { registry: registry.code, action, code: foundData.code });
            onClose();
            if (!res?.ok) {
              enqueueSnackbar("Ошибка", { variant: "error" });
              return;
            }
            enqueueSnackbar("Успешно выполнено", { variant: "success" });
            reload();
          })();
          return <ContourBackdrop />;
        },
        type: "Dialog",
      });
      return;
    }
    if (action === "editcomp") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;

      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => <Components onOk={onOk} data={foundData} />,
        type: "Dialog",
      });
    }
    if (action === "print") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;

      if (registry.code === "answer_registry") {
        const title = references.survey?.find(({ code }) => code === foundData.survey)?.value;
        confirmationDialog.getConfirmation({
          content: (onOk, onClose) => <PdfSurveyLoader onOk={onOk} registry={registry.code} code={foundData.code} title={title || "Обследование"} />,
          type: "Dialog",
        });
        return;
      }
      
      const title = getPdfModalTitle(foundData);
      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => <PdfRegistryLoader onOk={onOk} title={title} registry={registry.code} code={foundData.code} name={foundData.value} version={foundData.version} />,
        type: "Dialog",
      });
      return;
    };

    if (action === "document") { 
      if (rows.length === 0) return;
      const foundCodes = rows.map((id) => tableData.find((row) => row.id === id)).map(({ code }) => code); 
      confirmationDialog.getConfirmation({ 
        content: (onOk, onClose) => <PdfDocLoader onOk={onOk} indicators={foundCodes} />, 
        type: "Dialog", 
      }); 
      return; 
    }

    if (action === "document_ref") { 
      if (rows.length === 0) return;
      const foundCodes = rows.map((id) => tableData.find((row) => row.id === id)).map(({ code }) => code); 
      confirmationDialog.getConfirmation({ 
        content: (onOk, onClose) => <PdfDocRefLoader onOk={onOk} registryCode={registry.code} rowCode={foundCodes} />, 
        type: "Dialog", 
      }); 
      return; 
    }

    if (action === "util_audit") {
      const res = await api.send("util/audit", {
        operation: target
      });

      if (!res?.ok) {
        enqueueSnackbar("Ошибка", { variant: "error" });
      } else {
        enqueueSnackbar("Операция запущена", { variant: "success" });
      }

      return;
    }

    if (action === "download_upload") {
      if (rows.length === 0) return;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      const response = await fetch(process.env.REACT_APP_API + "/download/upload", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          token: api.token,
          code: foundData.code,
          version: foundData.version
        }),
      });

      if (!response.ok) {
        enqueueSnackbar("Файл не существует", { variant: "error" });
        return;
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition.match(/filename="([^"]+)"/)[1];

      const blob = await response.blob();
      const a = document.createElement("a");
      a.download = filename;
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return;
    }

    if (action === "download_export") {
      if (rows.length === 0) return;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      const response = await fetch(process.env.REACT_APP_API + "/download/export", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          token: api.token,
          code: foundData.ext_code,
          version: foundData.version
        }),
      });

      if (!response.ok) {
        enqueueSnackbar("Файл не существует", { variant: "error" });
        return;
      }
      
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition.match(/filename="([^"]+)"/)[1];;

      const blob = await response.blob();
      const a = document.createElement("a");
      a.download = filename;
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return;
    }

    if (action === "data_download") {
      const code = !!registry.code ? registry.code : registry.object_code;
      confirmationDialog.getConfirmation({ 
        content: (onOk, onClose) => <DownloadFile onClose={onClose} code={code} payload={{ filter: filtersHandler?.() || [], sort_order: sortOrder.current }} />, 
        type: "Dialog", 
      });
      return;
    }

    if (action === "document_registry") { 
      confirmationDialog.getConfirmation({ 
        content: (onOk, onClose) => <PdfDocRegLoader onOk={onOk} registry={registry} />, 
        type: "Dialog", 
      }); 
      return; 
    }
    
    if (action === "export_indicator") {
      const response = await confirmationDialog.getConfirmation({
        title: value,
        text: "Вы действительно хотите выполнить операцию?",
        width: "sm",
        type: "Warning",
      });
      if (!response) return;
      confirmationDialog.getConfirmation({ 
        content: (onOk, onClose) => <ExportIndicator onClose={onClose} />, 
        type: "Dialog", 
      });
      return;
    }

    if (action === "tab") {
      window.open(target, "_blank");
      return;
    };
    if (action === "recalculation") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <Calculation 
            onOk={onOk} 
            title={value}
            calculationCode={!registry.code?.startsWith("indicator") ? foundData?.indicator_code || foundData?.indicator : null} 
            indicatorCode={registry.code?.startsWith("indicator") ? foundData?.code : null} 
          />
        ),
        type: "Dialog",
      });
      return;
    }
    if (action === "showdatacomp") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      
      confirmationDialog.getConfirmation({
        content: (onOk) => (
          <ShowDataComp 
            onOk={onOk} 
            title={value}
            indicatorCode={foundData?.indicator_code || foundData?.code || foundData?.indicator}
          />
        ),
        type: "Dialog",
      });
      return;
    }

    if (action === "jump") {
      const foundData = tableData.find(({ id, code }) => id === rows[0] || code === rows[0]);
      if (!foundData) return;
      const tree = await api.getTree();
      return jumps.map(({ registry_to, field_from, field_to }) => {
        const foundTree = tree.find(({ id }) => id === registry_to);
        const foundRegistry = allRegistries.find(({ code }) => code === registry_to);
        const title = foundTree?.properties?.title || foundRegistry?.label || registry_to;
        return {
        text: title,
        icon: foundTree?.properties?.icon || foundRegistry?.icon,
        disabled: foundTree?.id === "algorithm_registry" && foundData.is_comp === "1" && foundData.is_ind !== "1", 
        handler: () => {
          dispatch({ type: "SET_CURRENT", value: { current: { 
            id: registry_to + "-filter-" + foundData[field_from],
            registry: registry_to,
            hideInParams: true,
            withoutLoadSettings: true,
            filter: [[null, field_to, "=", foundData[field_from], false, null, "AND"]],
            properties: { title, icon: foundTree?.properties?.icon || foundRegistry?.icon },
            type: "registry"
          } } })
        }
      }});
    }

    if (action === "showdata") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      const res = await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => <ShowData  code={foundData.code} registry={registry.code} onOk={onOk} onCancel={onClose} />,
        type: "Dialog",
      }); 
      if (!Array.isArray(res?.fields) || !res?.references) return;

      const fields = res.fields;
      const references = res.references;

      let sorting = [];

      const handleSortOrderChange = (dim, order) => {
        const dimName = dim.properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
        if (dimName !== "territory") return false;
        dim.properties.sortOrder = order;
        sorting = order === "none" ? [] : [[dimName, order]];
        return true;
      };

      const schema = { 
        props: [
          {
            type: "Table",
            value: "showdata",
            componentProps: {
              removeTopBorder: true,
              customOrderChange: handleSortOrderChange
            },
            data: {
              id: "showdata-table",
              trDims: fields.map(({ code, label, field, type }) => {
                const fieldType = tableTypeByWidgetFieldType(type);
                return {
                  id: code,
                  properties: {
                    title: label,
                    name: code,
                    axis: "data",
                    position: 0,
                    sortOrder: "none",
                    filter: [],
                    textFilter: {},
                    type: fieldType,
                    isEditable: false,
                    customCellRenderer: fieldType === "Memo" ? (cell, __, row) => {
                      if (row[code + "__value"]) return row[code + "__value"];
                      return cell;
                    } : fieldType === "Dictionary" ? (cell) => {
                      const foundReference = references[field].find(({ code }) => typeof code === "number" ? code === +cell : code === cell);
                      return foundReference?.value || cell;
                    } : null
                  }
                }
              }),
              fullCustomToolbar: true,
              apiMethod: "registries/data/list",
              apiPayload: { code: foundData.code, registry: registry.code, page_size: 20 },
              toolbar: [],
            }
          }
        ] 
      };
      confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <ItemDialog
            title={`${foundData.ext_code}_${foundData.periodicity}_${foundData.period_date_last}`}
            schema={schema}
            maxWidth={800}
            minHeight="max(400px, 60vh)"
            type=""
            customActions={[{
              onClick: () => {
                confirmationDialog.getConfirmation({
                  content: (onOk) => {
                    (async function() {
                      try {
                        const response = await fetch(process.env.REACT_APP_API + "/registries/data/download", {
                          method: "POST",
                          headers: { "Content-Type": "application/json; charset=utf-8" },
                          body: JSON.stringify({ token: api.token, code: foundData.code, sort_order: sorting, registry: registry.code, page_size: 50, page_number: 0 })
                        });
                        if (!response.ok) throw new Error("HTTP error " + response.status);
                        
                        const contentDisposition = response.headers.get('Content-Disposition');
                        const filename = contentDisposition.match(/filename="([^"]+)"/)[1];

                        const blob = await response.blob();
                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                      } catch (error) {
                        console.log("Error occurred while fetching and downloading the file", error);
                      } finally {
                        onOk();
                      };
                    })();
                    return <ContourBackdrop zIndex={100000} />
                  },
                  type: "Dialog"
                });
              },
              label: "Скачать",
              disabled: !res.listDataLength || res.listDataLength === 0,
            }]}
            
            withAction={["Закрыть", null]}
            prop={{ current: { properties: {} }}}
            onOk={onOk}
            onClose={onClose}
          />
        ),
        type: "Dialog",
      });
      return;
    }
    if (action === "dashboard") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      const id = await getDashboardId(foundData);
      handleDashboardClick({ id, closeModal });
      return;
    }
    if (action === "protocol") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      confirmationDialog.getConfirmation({
        content: (onOk) => (
          <ShowDataDownload 
            onOk={onOk} 
            filename="protocol.csv"
            code = {foundData.code}
            registry = {registry.code} 
          />
        ),
        type: "Dialog",
      });
      return;
    }
    if (action === "create") {
      const foundWidget = registry.widget.find(({ widget_type }) => widget_type === "card") || registry.widget[0];
      const { schema, baseValue } = getCardWidgetFields({ foundWidget, action, editMode: true, hideOperations: true });

      const response = await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <ViewRowCard
            title={value}
            schema={schema}
            withIndicatorFilters={withIndicatorFilters(registry?.code)}
            updateSchema={(filter, newData) => getCardWidgetFields({ filter, action, newData, foundWidget, editMode: true, hideOperations: true })}
            maxWidth={1000}
            type=""
            prop={{ current: { properties: baseValue }}}
            onOk={onOk}
            onClose={onClose}
          />
        ),
        type: "Dialog",
      });
      if (!response?.v) return;
      const data = response.v;
      if (parentRowCode) data[parentRowField] = parentRowCode;
      columns.filter(({ properties }) => properties.type === "const").forEach(({ field }) => {
        if (!field.source || !field.value) return;
        data[field.source] = field.value;
      })
      if (dependentRegistries.includes(registry.code)) {
        Object.entries(columnFilters[registry.code]).forEach(([key, value]) => data[key] = value);
      }
      if (rowData?.version) data.version = rowData.version;
      const result = await api.call("registries/create", { code: registry.code, data })
      if (!result || result?.error || !result.id) return;
      createdIds.current = [...createdIds.current, result.id];
      if (typeof objectValue === "object") onChange?.(objectValue, registry.code + "-table", [...tableData, result]);
      enqueueSnackbar("Сохранено", { variant: "success" });
      reload();
      return;
    }
    if (action === "remove") {
      if (rows.length !== 1) return;
      const response = await confirmationDialog.getConfirmation({
        title: value,
        text: "Вы действительно хотите удалить выбранные строки?",
        width: "sm",
        type: "Warning",
      });
      if (!response) return;
      
      const foundData = tableData.find((row) => row.id === rows[0]);
      if (!foundData) return;

      const dependentRegistry = getDependentRegistry(registry.code);
      if (dependentRegistry) {
        const childData = await api.call("registries/list", { 
          code: getDependentRegistry(registry.code),
          filter: [
            [null, "parent_code", "=", parentRowCode, false, null, "AND"],
            [null, "periodicity", "=", foundData.periodicity, false, null, "AND"],
            [null, "data_type", "=", foundData.data_type, false, null, "AND"],
          ],
          page_number: 0,
          page_size: 50,
          sort_order: [],
        });
  
        if (!Array.isArray(childData)) return;
  
        await Promise.all(childData.map((foundData) => {
          const data = { code: foundData.code };
          if (parentRowCode) data[parentRowField] = parentRowCode;
          return api.call("registries/remove", { code: getDependentRegistry(registry.code), data })
        }))
      }

      const data = { code: foundData.code };
      if (parentRowCode) data[parentRowField] = parentRowCode;
      await api.call("registries/remove", { code: registry.code, data })

      if (typeof objectValue === "object") onChange?.(objectValue, registry.code + "-table", tableData.filter(({ id }) => !rows.includes(id)));
      enqueueSnackbar("Готово", { variant: "success" });
      reload();
      return;
    }
    if (action === "submit") {
      const res = await api.send("form/save", {
        data: tableData,
        code: registry.table,
      });

      if (!res?.ok) {
        enqueueSnackbar("Ошибка", { variant: "error" });
      } else {
        enqueueSnackbar("Сохранено", { variant: "success" });
      }
      
      reloadHandler();
      return;
    }
    if (action === "calculate") {
      const res = await api.send("form/calculate", {
        data: tableData,
        code: registry.table
      });

      if (!res?.ok) {
        enqueueSnackbar("Ошибка", { variant: "error" });
      } else {
        enqueueSnackbar("Готово", { variant: "success" });
      }
      
      reloadHandler();
      return;
    }
    if (action === "reload") return reload();
    if (action === "upload") return setUploadFormVisibility(true);
    if (action === "filter_reset") {
      clearFilters();
      resetFilters?.();
      return ;
    }

    if (action === "add") {
      const foundWidget = registry.widget.find(({ code }) => code === target);
      if (!foundWidget) return;
      const { schema, baseValue } = getCardWidgetFields({ foundWidget, action, editMode: true, hideOperations: true });

      const response = await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <ViewRowCard
            title={foundWidget.value}
            schema={schema}
            withIndicatorFilters={withIndicatorFilters(registry?.code)}
            updateSchema={(filter, newData) => getCardWidgetFields({ filter, action, newData, foundWidget, editMode: true, hideOperations: true })}
            maxWidth={1000}
            minHeight="calc(100vh - 40px)"
            withConfirmation
            type=""
            prop={{ current: { properties: baseValue }}}
            onOk={onOk}
            onClose={onClose}
          />
        ),
        type: "Dialog",
      });
      if (!response) return;
      const data = response.v;
      foundWidget.field_group?.forEach(group => {
        group?.field?.forEach((field) => {
          if (field.field_type !== "const") return;
          data[field.source] = field.value;
        })
      })
      const result = await api.call("registries/add", { code: registry.code, data })
      if (!result || result?.error || !result.id) return;
      createdIds.current = [...createdIds.current, result.id];
      enqueueSnackbar("Сохранено", { variant: "success" });
      reload();
      return;
    }

    if (action === "survey") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      const title = references.survey?.find(({ code }) => code === foundData.survey)?.value;
      dispatch({ type: "SET_CURRENT", value: { current: { 
        id: foundData.code + "-survey",
        code: foundData.code,
        registry: registry.code,
        hideInParams: true,
        properties: {title: title || foundData.value, hint: null, icon: null},
        type: "survey"
      } } })
      return;
    }

    if (action === "edit" || action === "edit_card") {
      const foundWidget = target ? registry.widget.find(({ code }) => code === target) : registry.widget[0];
      if (!foundWidget) return;
      const foundRow = tableData.find(({ id }) => id === rows[0]);
      if (!foundRow) return;
      const foundData = { ...foundRow };
      const approvedRow = isApprovedRow(foundData)
      const fixedRow = isFixedRow(foundData);

      const prevVersion = foundData.version;
      let newVersion = null;
      if (approvedRow || fixedRow) {
        const confirmationResult = await confirmationDialog.getConfirmation({
          title: "Создать новую версию",
          text: "Вы действительно хотите создать новую версию?",
          width: "sm",
          type: "Warning",
        })
        if (!confirmationResult) return;

        let cantCreateNewVersion = false;
        await confirmationDialog.getConfirmation({
          content: (onOk, onClose) => {
            (async function() {
              const allVersions = await api.call("registries/list", {
                page_size: 50,
                page_number: 0,
                sort_order: [["version", "DESC"]],
                code: registry.code,
                filter: [[null, "code", "=", foundData.code, false, null, "AND"]],
              });

              if (Array.isArray(allVersions) && allVersions.length > 0) {
                const lastVersionData = allVersions[0];
                
                const approvedRow = isApprovedRow(lastVersionData)
                const fixedRow = isFixedRow(lastVersionData);
                if (!approvedRow && !fixedRow) {
                  cantCreateNewVersion = true;
                  onOk();
                  return;
                }

                const version = +lastVersionData.version;
                newVersion = isNaN(version) ? "2" : (version + 1).toString();
                foundData.version = newVersion;
              }

              onOk();
            })();
            return <ContourBackdrop />
          },
          type: "Dialog",
        });

        if (cantCreateNewVersion) {
          await confirmationDialog.getConfirmation({
            title: "Нельзя создать новую версию",
            text: "Существует незафиксированная версия этой записи",
            width: "sm",
            type: "Warning",
          })
          return;
        }
      }

      const subindicatorFilters = newVersion ? [
        ["(", "version", "=", prevVersion, false, null, "OR"],
        [null, "version", "=", newVersion, false, ")", "AND"]
      ] : [
        [null, "version", "=", foundData.version, false, null, "AND"]
      ];

      const { schema, indicators } = getCardWidgetFields({ 
        foundWidget, 
        action, 
        foundData, 
        editMode: true, 
        hideOperations: approvedRow,
        subindicatorFilters
      });

      const response = await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => (
          <ViewRowCard
            title={(approvedRow || fixedRow) ? getCreateModalTitle(registry, foundData) : getEditModalTitle(registry, foundData)}
            schema={schema}
            withIndicatorFilters={withIndicatorFilters(registry?.code)}
            updateSchema={(filter, newData) => getCardWidgetFields({ filter, action, newData, foundWidget, foundData, subindicatorFilters, editMode: true, hideOperations: approvedRow })}
            maxWidth={1000}
            minHeight="calc(100vh - 40px)"
            confirmationText={(approvedRow || fixedRow) && "Вы действительно хотите создать новую версию?"}
            withConfirmation
            type=""
            prop={{ current: { properties: foundData }}}
            onOk={onOk}
            onClose={onClose}
          />
        ),
        type: "Dialog",
      });
      if (!response) return;
      const data = response.v;

      setInternalLoading(true);

      (async function() {
        indicators.forEach(code => {
          const data = response.v[code + "-table"];
          if (!data) return;
          api.call("registries/save", { code, data });
          delete response.v[code + "-table"];
        })
  
        const result = await api.call("registries/edit", { code: registry.code, data })
        if (!result || result?.error) return;
  
        if (newVersion) {
          await Promise.all(indicators.map(code => (
            new Promise(async (resolve) => {
              const result = await api.send("registries/list", {
                code,
                filter: [
                  [null, "parent_code", "=", foundData?.indicator_code || foundData?.indicator || foundData?.code, false, null, "AND"],
                  [null, "version", "=", prevVersion, true, null, "AND"]
                ],
                page_number: 0,
                page_size: 50,
                sort_order: [],
              });
  
              if (!Array.isArray(result)) {
                resolve(true);
                return;
              }
  
              await Promise.all(result.map((data) => {
                data.version = newVersion;
                delete data.code;
                return api.send("registries/create", { code, data })
              }));
  
              resolve(true);
            })
          )));
        }
  
        enqueueSnackbar("Сохранено", { variant: "success" });
        reload();
      })();
      
      setInternalLoading(false);
          
      return;
    }

    if (action === "emiss_import") {
      setInternalLoading(true);
      const result = await api.call("util/import/run");
      setInternalLoading(false);

      if (result?.error || result?.ok === false) {
        enqueueSnackbar(result?.message || "Ошибка запроса", { variant: "error" });
      } else {
        enqueueSnackbar("Готово", { variant: "success" });
      }

      return;
    }

    if (action === "duplicate") {
      if (rows.length === 0) return;
      const foundData = tableData.find((row) => row.id === rows[0]);
      if (!foundData) return;
      const data = {};
      Object.keys(foundData).forEach(key => {
        if (key === "id" || key === "code") return;
        if (key.split(DUPLICATE_INDENTIFICATOR).length === 2) return;
        data[key] = foundData[key];
      })
      const result = await api.call("registries/create", { code: registry.code, data });
      if (!result || result?.error || !result.id) return;
      createdIds.current = [...createdIds.current, result.id];
      enqueueSnackbar("Сохранено", { variant: "success" });
      if (typeof objectValue === "object") {
        onChange?.(objectValue, registry.code + "-table", [...tableData, result]);
        return;
      }
      reload();
      return;
    }

    if (action === "delete") {
      if (rows.length === 0) return;
      const response = await confirmationDialog.getConfirmation({
        title: value,
        text: "Вы действительно хотите удалить выбранные строки?",
        width: "sm",
        type: "Warning",
      });
      if (!response) return;

      const responses = await Promise.all(rows.map((id) => {
        const foundData = tableData.find((row) => row.id === id);
        if (!foundData) return Promise.resolve(null);
        return api.call("registries/delete", { code: registry.code, data: { version: foundData.version, code: foundData.code } })
      }))

      const errorResponses = responses.filter(result => !result || result.error || !result.ok);
      if (errorResponses.length === responses.length) {
        enqueueSnackbar("Ошибка при удалении", { variant: "error" });
        reload();
        return;
      }

      if (errorResponses.length > 0) {
        const success = responses.length - errorResponses.length;
        const total = responses.length;
        enqueueSnackbar(`Элементов удалено: ${success} из ${total}`, { variant: "warning" });
        reload();
        return;
      }

      enqueueSnackbar("Готово", { variant: "success" });
      reload();
      return;
    }

    if (action === "file_link") {
      const foundWidget = registry.widget.find(({ code }) => code === target);
      if (!foundWidget) return;
      const foundField = foundWidget.field_group[0].field.find(({ field_type }) => field_type === "file_link");
      if (!foundField) return;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      const value = foundData[foundField.source];
      window.open(value);
      return;
    }

    if (action === "registry") {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return;
      dispatch({ type: "SET_CURRENT", value: { current: { 
        id: foundData.code,
        hideInParams: true,
        registry: foundData.code,
        properties: {title: foundData.value || "Справочник", hint: null, icon: null},
        type: "registry"
      } } })
      return;
    }

    if (action !== "card") return;
    const foundWidget = registry.widget.find(({ code }) => code === target);
    if (!foundWidget) return;
    const foundData = tableData.find(({ id }) => id === rows[0]);
    const { schema, indicators } = getCardWidgetFields({ foundWidget, action, foundData, editMode: false });

    const dashboardOperation = foundWidget.operation?.find(({ action }) => action === "dashboard");
    let dashboardLink = null;
    if (dashboardOperation) {
      await confirmationDialog.getConfirmation({
        content: (onOk, onClose) => {
          (async function() {
            dashboardLink = await getDashboardId(foundData)
            onClose();
          })();
          return <ContourBackdrop />
        },
        type: "Dialog",
      });
    }

    const actions = foundWidget.operation?.map(operation => ({
      onClick: ({ closeModal }) => {
        if (operation.action === "dashboard") {
          handleDashboardClick({ id: dashboardLink, closeModal });
          return;
        }
        handleOperationClick({ ...operation, closeModal, rows, reload, tableData })
      },
      label: operation.value,
      hint: operation.hint,
      disabled: operation.action === "dashboard" ? !dashboardLink : false,
    }));

    let url = `${process.env.PUBLIC_URL}/${params.registry}/${target}/${foundData.code}/`; 
    if (foundData.version) url += `${foundData.version}/`
    navigate(url);

    const response = await confirmationDialog.getConfirmation({
      content: (onOk, onClose) => (
        <ItemDialog
          title={getViewModalTitle(foundData)}
          schema={schema}
          maxWidth={1000}
          minHeight="calc(100vh - 40px)"
          withConfirmation
          withAction={["Закрыть", null]}
          type=""
          customActions={actions}
          prop={{ current: { properties: foundData }}}
          onOk={onOk}
          onClose={onClose}
        />
      ),
      type: "Dialog",
    });
    clearWidgetSearchParams();
    if (!response) return;
    indicators.forEach(code => {
      const data = response.v[code + "-table"];
      if (!data) return;
      api.call("registries/save", { code, data });
    })
    reload()
  }, [filtersHandler, registry, columns, getCardWidgetFields, jumps, navigate, params, confirmationDialog, onChange, clearWidgetSearchParams, getSelected, api, handleDashboardClick, pushDataHandler, changeRowsHandler, removeDataHandler, reloadHandler, enqueueSnackbar, clearFilters, resetFilters, getDashboardId]);

  React.useEffect(() => {
    columns.forEach((column) => {
      if (column.properties.field_type !== "reference") return;

      const source = /* column.field.reference ||  */column.properties.name?.split(DUPLICATE_INDENTIFICATOR)[0];
      if (!Array.isArray(references[source])) {
        column.dict = [];
        return;
      }

      const parents = {};
      const dicts = [];

      let index = 0;
      references[source].forEach((reference) => {
        const name = column.properties.source_field ? reference[column.properties.source_field] : reference.value;
        if (!name) return;

        if (reference.parent_code) {
          const parentValue = parents[reference.parent_code];
          const node = { name, value: name, id: reference.code, parent: reference.parent_code, index: index++ };
          parents[reference.parent_code] = parentValue ? [...parentValue, node] : [node];
          return;
        }

        dicts.push({ name, value: name, id: reference.code, index: index++ });
      })

      function applyChildrens(dict) {
        if (!parents[dict.id]) return dict;
        return { ...dict, size: parents[dict.id].length, childrens: parents[dict.id].map(applyChildrens) };
      }

      column.dict = dicts.map(applyChildrens)
    });
  }, [columns, registry, references]);

  const handleCellEdit = React.useCallback(async (id, data, column, val, col, rows) => {
    const foundDataIndex = tableData.findIndex((data) => data.id === id);
    if (foundDataIndex === -1) return;

    const foundStatGridWidget = registry.widget.find((widget) => widget.widget_type === "stat_grid");
    if (foundStatGridWidget) {
      const foundRow = statGridRow.find(({ code }) => code === tableData[foundDataIndex].row_number);
      if (!foundRow || foundRow.readonly) {
        if (foundRow?.readonly) enqueueSnackbar("Нельзя редактировать ячейку", { variant: "error" });
        data[col] = tableData[foundDataIndex][column.name];
        return;
      }

      const foundSelfCell = foundStatGridWidget.cell.find(({ coordinate }) => coordinate?.includes("row=") && coordinate.split("row=")[1] === foundRow.row_number);
      if (foundSelfCell && foundSelfCell.form_rule?.match(/>|<|>=|<=/g)) {
        let canEdit = true;
        try {
          let expression = foundSelfCell.form_rule
          expression = expression.replaceAll(/\[.*?\]./g, "");
          expression = expression.replace(/{.}/g, (value) => {
            const row_number = value.replaceAll(/{|}/g, "")
            if (!row_number) return 0;
            const foundRow = statGridRow.find((row) => row.row_number === row_number);
            if (!foundRow) return 0;
            const foundData = tableData.find((data) => data.row_number === foundRow.code);
            return foundData ? foundData.id === id ? val : foundData.fact || 0 : 0;
          });
          if (!eval(val + expression)) throw new Error();
        } catch (error) {
          enqueueSnackbar(foundSelfCell.error_message || "Ошибка валидации", { variant: "error" });
          data[col] = tableData[foundDataIndex][column.name];
          canEdit = false
        }

        if (!canEdit) return;
      }

      let canEdit = true;
      const formulaList = [];

      foundStatGridWidget.cell.forEach((cell) => {
        if (!cell.coordinate?.includes("row=")) return;
        const foundRow = statGridRow.find(({ row_number }) => row_number === cell.coordinate.split("row=")[1]);
        if (!foundRow || !cell.formula) return;
        const formula = cell.formula.replaceAll(/{.}/g, (value) => {
          const row_number = value.replaceAll(/{|}/g, "")
          if (!row_number) return 0;
          const foundRow = statGridRow.find((row) => row.row_number === row_number);
          if (!foundRow) return 0;
          const foundData = tableData.find((data) => data.row_number === foundRow.code);
          return foundData ? foundData.id === id ? val : foundData.fact || 0 : 0;
        });

        try {
          const formulaResult = eval(formula);
          const rowIndex = statGridRow.findIndex(({ row_number }) => row_number === cell.coordinate.split("row=")[1]);
          formulaList.push({ rowIndex, col, formulaResult });
        } catch (error) {
          enqueueSnackbar(cell.error_message || "Ошибка валидации", { variant: "error" });
          canEdit = false;
        }
      });  
      
      if (!canEdit) {
        data[col] = tableData[foundDataIndex][column.name];
        return;
      }

      formulaList.forEach(({ rowIndex, col, formulaResult }) => {
        rows[rowIndex][col] = formulaResult;
        tableData[rowIndex].fact = formulaResult;
      })
    }

    tableData[foundDataIndex][column.name] = val;

    if (typeof objectValue === "object") onChange?.(objectValue, code + "-table", tableData);
    recalculateHandler();
  }, [tableData, registry, statGridRow, objectValue, recalculateHandler, enqueueSnackbar, code, onChange]);
  
  const checkButtonIsActive = React.useCallback(async (action, condition, code, { rows, tableData }) => {
    if (action === "download_upload") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      return foundData?.original_files !== null && foundData?.original_files !== "{}"
    }

    if (action === "download_export") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      return foundData?.original_files !== null && foundData?.original_files !== "{}"
    }

    if (action === "jump") {
      if (rows.length !== 1) return false;
      return jumps.length !== 0; 
    }

    if (action === "showdata") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return false;

      if (registry.code === "calculation_registry") {
        const statusValues = foundData.wf_status.split(".");
        return !!foundData.code && statusValues[2] === "1" && statusValues[8] === "1";
      }

      return !!foundData.code;
    }

    if (action === "showdatacalc") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.code || !foundData.wf_status) return false;
      const statusValues = foundData.wf_status.split(".");
      return statusValues[2] === "1" && statusValues[8] === "1";
    }

    if (action === "sign") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.code || !foundData.wf_status) return false;
      const statusValues = foundData.wf_status.split(".");
      return statusValues[2] === "1" && statusValues[3] === "0" && statusValues[8] === "1";
    }
    
    if (action === "survey") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.wf_status) return true;
      return !foundData.wf_status.startsWith("1.0.1.1")
    }
    
    if (action === "document") {
      if (rows.length === 0) return false;
      return !rows.find((row) => {
        const foundData = tableData.find(({ id }) => id === row);
        if (!foundData?.wf_status) return true;
        const statusValues = foundData.wf_status.split(".");
        return statusValues[2] === "0" || statusValues[16] === "0";
      });
    }

    if (action === "showdatacomp") {
      if (rows.length > 1) return false;
      if (rows.length === 0) return true;
      if (registry.code !== "indicator_np_fp") return true;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      return foundData ? !parseBoolean(foundData.is_comp) : false;
    }

    if (action === "recalculation") {
      if (rows.length === 0) return true;
      if (rows.length > 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.wf_status) return false;
      const statusValues = foundData.wf_status.split(".");
      return statusValues[17] === "1";
    }
    
    if (action === "fix") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.code || !foundData.wf_status) return false;
      const statusValues = foundData.wf_status.split(".");
      if (registry.code === "algorithm_registry" && (statusValues[2] !== "1" || statusValues[16] !== "0")) return false;
      if (statusValues[16] !== "0") return false;

      const foundTableWidget = registry.widget.find(({ widget_type }) => widget_type === "grid");
      if (!foundTableWidget) return false;
      const foundEditAction = foundTableWidget.operation.find(({ action }) => action === "edit");
      if (!foundEditAction) return false;
      const foundEditCardWidget = registry.widget.find(({ code }) => code === foundEditAction.target);

      const requiredFieldsKeys = [];
      foundEditCardWidget.field_group.forEach(({ field }) => {
        field.forEach(({ field_type, required, source, visibility }) => {
          const visible = checkVisibility(visibility, (code, value) => {
            if (dependentRegistries.includes(registry?.code)) return columnFilters?.[registry.code]?.[code] === value;
            return parseBoolean(foundData?.[code]) === parseBoolean(value);
          });
          if (!visible) return;
          if (!parseBoolean(required)) return;
          if (field_type === "subref") return;
          requiredFieldsKeys.push(source);
        });
      })

      const foundInvalidField = requiredFieldsKeys.find(
        (key) => {
          if (foundData[key] === undefined || foundData[key] === null) return true;
          if (key.includes("phone")) {
            if (!foundData[key].startsWith("+7")) return true;
            return foundData[key].replaceAll(/\D/g, "").length !== 11;
          }
          if (key.includes("mail")) {
            const [firstPart, secondPart] = foundData[key].split("@");
            if (!firstPart || !secondPart) return true;
            const [firstDotPart, secondDotPart] = foundData[key].split(".");
            return !firstDotPart || !secondDotPart;
          }
          return foundData[key] === "";
        }
      );

      return !foundInvalidField;
    }

    if (action === "fix_rosstat") {
      if (!userData?.biportal?.canfix && !userData?.biportal?.rosstat) return false;
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.code || !foundData.wf_status) return false;
      const statusValues = foundData.wf_status.split(".");
      return statusValues[16] === "1" && statusValues[17] === "0";
    }
    
    if (action === "edit") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData || !foundData.wf_status) return true;
      const statusValues = foundData.wf_status.split(".");
      return statusValues[3] !== "1" || statusValues[4] !== "0";
    }

    if (action === "print") return rows.length === 1;

    if (registry.code?.startsWith("data_registry") && rows.length === 1 && (code === "has_comp" || code === "is_comp")) {
      const foundData = tableData.find(({ id }) => id === rows[0]);
      if (!foundData) return false;
      const indicator = await api.send("registries/list", {
        page_size: 50,
        page_number: 0,
        sort_order: [],
        code: "indicator",
        filter: [[null, "code", "=", foundData.indicator_code, false, null, "AND"]],
      });
      if (!Array.isArray(indicator) || indicator.length === 0) return false;
      const [code, value] = condition.split("=");
      if (!value) return true;
      return parseBoolean(indicator[0][code]) !== parseBoolean(value);
    }

    if (!condition) return true;
    if (Object.keys(TOOLBAR_DISABLE_CASES).includes(condition)) return condition;

    if (condition === "approved") {
      if (rows.length === 0) return false;
      return !rows.find((rowId) => {
        const foundData = tableData.find(({ id }) => id === rowId);
        return isApprovedRow(foundData);
      });
    }

    if (condition === "approved_one") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      return isApprovedRow(foundData);
    }

    if (condition === "not_approved") {
      if (rows.length === 0) return false;
      return !rows.find((rowId) => {
        const foundData = tableData.find(({ id }) => id === rowId);
        return !isApprovedRow(foundData);
      });
    }

    if (condition === "not_approved_one") {
      if (rows.length !== 1) return false;
      const foundData = tableData.find(({ id }) => id === rows[0]);
      return !isApprovedRow(foundData);
    }

    const conditions = condition.split(", ");
    const foundData = tableData.find(({ id }) => id === rows[0]);
    return !conditions.find(condition => {
      if (condition === "equal_one") return rows.length !== 1;
      const [code, value] = condition.split("=");
      if (!value) return true;
      return parseBoolean(foundData?.[code]) !== parseBoolean(value);
    })
  }, [registry, jumps, columnFilters, userData, api]);

  const indicatorFilters = React.useCallback(() => {
    if (!withIndicatorFilters(registry?.code)) return [];
    if (activeFilters.length === FILTERS_ARRAY.length) return [];
    return activeFilters.map(filter => [null, filter.code, "=", true, false, null, "AND"]);
  }, [activeFilters, registry])

  const nesting = React.useMemo(() => {
    if (!registry) return false;

    const isNesting = isNestingRegistry(registry);
    if (!isNesting) return false;

    setLoading(true);
    setTimeout(() => setLoading(false), 0);
    return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, registry]);

  const schema = React.useMemo(() => {
    if (!registry || !columns) return null;
    const filter = [];
    const haveFilters = Array.isArray(settings?.filters) && settings?.filters.length > 0;
    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid" || widget.widget_type === "stat_grid");

    if (nesting && !haveFilters && !withoutParentCodeInNestedGrid) filter.push([null, "parent_code", "IS NULL", true, true, null, "AND"]);
    filter.push(...(filters || []));
    if (dependentRegistries.includes(code)) Object.keys(columnFilters[code]).forEach(key => filter.push([null, key, "=", columnFilters[code][key], false, null, "AND"]));
    filter.push(...indicatorFilters());

    return {
      value: "roles",
      type: "Table",
      data: {
        id: code + (parentCode || "") + "-table" + ++tableId,
        trDims: columns,
        apiMethod: "registries/list",
        apiClientSort: foundWidget.widget_type === "stat_grid" ? (listData) => {
          if (statGridRow.length === 0) return listData;
          return statGridRow.map(({ code }) => listData.find(({ row_number }) => row_number === code)).filter(data => !!data);
        } : null,
        apiPayload: { code, ...apiPayload, filter },
        fullCustomToolbar: true,
        itemsCounterRequest: !nesting && "registries/count",
        selectedCounter: true,
        singleRowSelect,
        toolbar: withOperations ? foundWidget.operation.filter(({action}) => action !== 'document_registry').map(({ icon, hint, code, action, target, active_condition, value }) => [
          icon,
          ({ tableData, rows, reload }) => handleOperationClick({action, code, value, target, reload, tableData, rows}),
          checkButtonIsActive.bind(null, action, active_condition, code),
          hint,
          null,
          null,
          value,
          action === "jump",
          ]) : [],
      },
    };
  }, [registry, columns, nesting, code, columnFilters, createdIds, parentCode, apiPayload, filters, indicatorFilters, singleRowSelect, withOperations, statGridRow, checkButtonIsActive, handleOperationClick]);

  const handleHiddenColumnsChange = React.useCallback(() => {
    if (current?.current?.withoutLoadSettings) return;
    const hiddenColumnCodes = columns.filter((column) => column.properties.hidden).map(column => column.id);
    const newSettings = settings || getEmptySettingsObject();
    newSettings.area.hidden = hiddenColumnCodes.length > 0 ? hiddenColumnCodes : null;
    setSettings(newSettings);
    api.call("registries/settings/save", { registry: code, ...newSettings });
    reloadHandler();
  }, [api, current, code, columns, reloadHandler, settings]);

  const handleColumnDrop = React.useCallback((columns) => {
    setColumns(columns);
    if (current?.current?.withoutLoadSettings) return;

    const sortedFixedColumns = columns
      .filter((d) => d.properties.axis === "fixed" && !d.properties.hidden)
      .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
      .map(column => column.id);

    const sortedDataColumns = columns
      .filter((d) => d.properties.axis === "data" && !d.properties.hidden)
      .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
      .map(column => column.id);

    const gridColumns = [...sortedFixedColumns, ...sortedDataColumns];
      
    const sortedFilterColumns = columns
    .filter((d) => d.properties.axis === "filter" && !d.properties.hidden)
    .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
    .map(column => column.id);

    const newSettings = settings || getEmptySettingsObject();
    newSettings.area.filters = sortedFilterColumns.length > 0 ? sortedFilterColumns : null;
    newSettings.area.defaults = gridColumns.length > 0 ? gridColumns : null;
    setSettings(newSettings);
    api.call("registries/settings/save", { registry: code, ...newSettings });
  }, [api, current, code, settings]);
  
  const handleSortOrderChange = React.useCallback((sortOrder) => {
    if (current?.current?.withoutLoadSettings) return;
    const newSettings = settings || getEmptySettingsObject();
    newSettings.sort_order = sortOrder.length > 0 ? sortOrder : null;
    setSettings(newSettings);
    api.call("registries/settings/save", { registry: code, ...newSettings });
  }, [api, current, code, settings]);

  const onFiltersChange = React.useCallback((filter) => {
    if (current?.current?.withoutLoadSettings) return;
    const newSettings = settings ? { ...settings } : getEmptySettingsObject();

    newSettings.filters = filter.length > 0 
      ? filter.map(filter => {
        const foundColumn = columns.find((column) => column.id === filter[1]);
        if (!foundColumn) return filter;

        if (filter[2] !== "IN") {
          filter[2] = foundColumn.properties.textFilter.operator;
          return filter;
        }

        filter[3] = foundColumn.properties.filter;
        return filter;
      }) 
      : null;

    setSettings(newSettings);
    api.call("registries/settings/save", { registry: code, ...newSettings });

    if (!hiddenColumns) return;
    dispatch({ type: "SET_HIDDEN_COLUMNS", value: {
      trDims: columns,
      onChange: handleHiddenColumnsChange,
    }});
  }, [settings, current, api, code, hiddenColumns, dispatch, columns, handleHiddenColumnsChange]);

  const handleFilterCheckboxChange = React.useCallback((propFilter) => {
    if (tableData.length === 0) return;
    let newActiveFilters = [];
    if (activeFilters.find(filter => filter.code === propFilter.code)) {
      if (activeFilters.length === 1) {
        newActiveFilters = FILTERS_ARRAY;
      } else {
        newActiveFilters = activeFilters.filter(filter => filter.code !== propFilter.code)
      }
    } else {
      newActiveFilters = [...activeFilters, propFilter];
    }
    setActiveFilters(newActiveFilters);
    changeRowsHandler([]);
    setTableData([]);

    if (!registry) return null;
    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid");
    if (!foundWidget) return;
    const fieldGroup = Array.isArray(foundWidget.field_group) ? foundWidget.field_group[0] : foundWidget.field_group; 
    if (!fieldGroup) return;

    setColumns(columns => {
      const newColumns = columns.map(column => {
        const field = fieldGroup.field.find(field => field.code === column.id);
        if (!field) return column;
        column.properties.hidden = !checkVisibility(field.visibility, (code) => {
          return !!newActiveFilters.find(filter => filter.code === code);
        });
        return column;
      });

      if (hiddenColumns) {
        dispatch({ type: "SET_HIDDEN_COLUMNS", value: {
          trDims: newColumns,
          onChange: handleHiddenColumnsChange,
        }});
      }

      return newColumns;
    })
  }, [tableData, activeFilters, changeRowsHandler, registry, hiddenColumns, dispatch, handleHiddenColumnsChange]);

  const customToolbarRightSideContent = React.useCallback(() => {
    if (!withIndicatorFilters(registry?.code)) return null;
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "1em", padding: "0 0.5em" }}>
        {FILTERS_ARRAY.map(filter => (
          <div style={{ display: "flex", alignItems: "center", color: "rgb(66, 71, 77)" }}>
            {filter.name}
            <CustomCheckbox onChange={() => handleFilterCheckboxChange(filter)} checked={activeFilters.includes(filter)} />
          </div>
        ))}
      </div>
    )
  }, [registry, activeFilters, handleFilterCheckboxChange]);

  const cantEditSelectedCell = React.useCallback((rowId, column) => {
    if (registry.code?.startsWith("subindicator")) {
      const condition = column.data.properties.editable_condition;
      if (!condition) return true;
      const [key, value] = condition.split("=");
      const foundData = tableData.find(({ id, code }) => id === rowId || code === rowId);
      if (!foundData) return true;
      return parseBoolean(foundData[key]) !== parseBoolean(value);
    }

    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "stat_grid");
    if (!foundWidget) return;
    const foundData = tableData.find(({ id }) => id === rowId);
    if (!foundData) return;
    const foundRow = statGridRow.find(({ code }) => code === foundData.row_number);
    if (!foundRow) return;
    const foundCell = foundWidget.cell?.find(({ coordinate }) => coordinate?.startsWith("row=") && coordinate.split("row=")[1] === foundRow.row_number);
    return foundCell ? !!foundCell.formula : false;
  }, [registry, statGridRow, tableData]);

  const handleSelectedRowsChanged = React.useCallback((rows) => {
    if (!followRegistries.includes(code)) return;
    const dependent = getDependentRegistry(code);
    if (rows.length === 0) {
      dispatch({ type: "SET_COLUMN_FILTER", value: { ...columnFilters, [dependent]: { periodicity: null, deadline: null, data_type: null } } })
      return;
    }
    const foundData = tableData.find(({ id, code }) => id === rows[0] || code === rows[0]);
    if (!foundData) return;
    dispatch({ type: "SET_COLUMN_FILTER", value: { ...columnFilters, [dependent]: { periodicity: foundData.periodicity, deadline: foundData.deadline, data_type: foundData.data_type } } })
  }, [code, dispatch, tableData]);

  const handleRowClick = React.useCallback((rowId, column) => {
    if (!column?.data?.properties?.throughout) return;
    const foundData = tableData.find(({ id, code }) => id === rowId || code === rowId);
    if (!foundData) return;
    const name = column.data.properties.name?.split(DUPLICATE_INDENTIFICATOR)[0];
    handleRegistryDialogOpen(
      column.data.field.reference || name, // TODO: remove properties.name
      column.data.properties.title,
      [[null, "code", "=", foundData[name], false, null, "AND"]]
    );
  }, [tableData, handleRegistryDialogOpen]);

  if (registryError) return (
    <CenteredContainer>
      Ошибка в данных
    </CenteredContainer>
  )

  if (columnFilterError) return (
    <CenteredContainer>
      {getDependentRegistryErrorText(code)}
    </CenteredContainer>
  )

  if (loading || !registry || columns.length === 0) return (
    <CenteredContainer>
      <CircularProgress color="primary" size={32} />
    </CenteredContainer>
  )

  return (
    <Container>
      <StyledTableEditor
        filtersHandler={setFiltersHandler}
        registry={registry}
        clearFiltersHandler={setClearFilters} 
        schema={schema} 
        idInFilters
        nesting={nesting}
        onRowClick={handleRowClick}
        createdIds={createdIds}
        onFiltersChange={onFiltersChange}
        onHiddenColumnsChange={handleHiddenColumnsChange}
        onOrderChange={handleSortOrderChange}
        onColumnDrop={handleColumnDrop}
        onEditCell={handleCellEdit}
        reloadHandler={setReloadHandler}
        cantEditCell={cantEditSelectedCell}
        recalculateHandler={setRecalculateHandler}
        setRowsHandler={setRowsHandler}
        onDropHiddenColumn={handleColumnsShow}
        pushDataHandler={setPushDataHandler}
        removeDataHandler={setRemoveDataHandler}
        customSortOrder={sortOrder}
        rowsHandler={handleSelectedRowsChanged}
        rerenderDeps={[registry, columns, filters]}
        customToolbarRightSideContent={customToolbarRightSideContent}
        getSelected={getSelected}
        tableSettings={!subRegistry}
        tableDataHandler={setTableData}
      />
      {internalLoading && createPortal(<ContourBackdrop zIndex={1000} />, document.getElementById("root"))}
      <FileUploadForm uploadFormOpened={uploadFormVisibility} setUploadFormOpened={setUploadFormVisibility} />
    </Container>
  );
}

const CenteredContainer = styled("div")`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = styled("div")`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex: 1;
`;

const StyledTableEditor = styled(TableEditor)`
  flex: 1;
`;

const CustomChip = styled(Chip)`
  gap: 4px;
  svg {
    margin: 0 !important;
  }
`;

const HeaderIconWrapper = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  
  svg {
    max-width: 20px;
    max-height: 20px;
  }
`;

export default React.memo(Registry);
