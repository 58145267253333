import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import cookie from "react-cookies";
import Draggable from "react-draggable";
import { ReactComponent as IconHeader } from '@mdi/svg/svg/page-layout-header.svg'

import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";

import Header from "components/Header";
import Splitter from "components/Splitters";
import TabbedView from "components/TabbedView";
import Workspace from "components/projectTree/workspace";
import PropertiesEditor from "components/projectTree/properties";
import HiddenColumns from "components/itemView/HiddenColumns";
import CustomSnowfall from "components/CustomSnowfall";

import { Icon, TooltipR } from "shared/ui/ToolBar";

import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Fab } from "@mui/material";

const SNOWFALL_ENABLED = !!process.env.REACT_APP_SNOWFALL;

const AppPage = ({ locale }) => {
  const confirmationDialog = useConfirmationDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { widget, row, version } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const rtl = useSelector((state) => state.rtl);
  const current = useSelector((state) => state.current);
  const apiError = useSelector((state) => state.apiError);
  const message = useSelector((state) => state.message);
  const propertiesData = useSelector((state) => state.propertiesData);
  const hiddenColumns = useSelector((state) => state.hiddenColumns);

  const initialDragPosition = React.useRef(false);
  const [openTreeButtonPosition, setOpenTreeButtonPosition] = React.useState({ x: 16, y: 16 });
  const [isHeaderVisible, setHeaderVisibility] = React.useState(true);

  const [isTreeVisible, setTreeVisibility] = React.useState(true);
  const [skipFirstCurrent, setSkipFirstCurrent] = React.useState(false);

  React.useEffect(() => {
    if (!skipFirstCurrent) {
      setSkipFirstCurrent(true);
      return;
    }

    if (!current || current.current?.hideInParams) {
      navigate(process.env.PUBLIC_URL + "/");
      return;
    }

    if (current.current?.id?.startsWith("http")) return;
    let url = `${process.env.PUBLIC_URL}/${current.current.id}/`;
    if (widget) url += `${widget}/`;
    if (row) url += `${row}/`;
    if (version) url += `${version}/`;
    navigate(url);
  }, [current]);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode !== 27) return;
      setTreeVisibility((isTreeVisible) => !isTreeVisible);
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  React.useEffect(() => dispatch({ type: "SET_HIDDEN_COLUMNS", value: null }), [current]);

  React.useEffect(() => {
    document.dir = rtl ? "rtl" : "ltr";
  }, [rtl]);

  const setLocale = (newLocale) => {
    if (locale._locale === newLocale) return;
    cookie.save("locale", newLocale, { path: process.env.PUBLIC_URL + "/", sameSite: "strict" });
    locale.activate(newLocale);
    window.dispatchEvent(new Event("CLOSE_ALL_PAGES"));
    dispatch({ type: "SET_LOCALE", value: newLocale });
    dispatch({ type: "SET_RTL", value: newLocale === "ar" });
  };

  React.useEffect(() => {
    const l = cookie.load("locale", { path: process.env.PUBLIC_URL + "/" });
    if (!l) return;
    setLocale(l);
  }, []);

  React.useEffect(() => {
    dispatch({ type: "SET_APP", value: "dap" });
  }, [dispatch]);

  React.useEffect(() => {
    if (!apiError) return;
    const Show = async () => {
      console.log(apiError.message || apiError || "Internal error", apiError.message, apiError);
      await confirmationDialog.getConfirmation({
        title: <span>Ошибка</span>,
        text: `${apiError.message || apiError || "Internal error"}`,
        width: "sm",
        type: "Error",
      });
    };
    Show();
    dispatch({ type: "SET_API_ERROR", value: null });
  }, [dispatch, apiError]);

  React.useEffect(() => {
    if (!message) return;
    enqueueSnackbar(message.text, { variant: "success" });
  }, [enqueueSnackbar, message]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        width: "100%",
        flexDirection: "column",
        position: "relative",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Header
        visible={isHeaderVisible}
        onTreeToggle={() => setTreeVisibility((v) => !v)} 
        onHeaderToggle={() => setHeaderVisibility(false)} 
      />
      {/* <div style={{ background: "#B4B5BB", height: 3, width: "100%" }} />
      <Breadcrumbs isTreeVisible={isTreeVisible} onTreeShow={() => setTreeVisibility(true)} /> */}
      {!isHeaderVisible && (
        <Draggable
          onStart={(event, data) => { initialDragPosition.current = { x: data.x, y: data.y } }}
          onStop={(event, data) => {
            event.stopPropagation();
            initialDragPosition.current = Math.max(Math.abs(initialDragPosition.current.x - data.x), Math.abs(initialDragPosition.current.y - data.y)) > 8
            initialDragPosition.current === false && setHeaderVisibility(true)
            setOpenTreeButtonPosition({ x: data.x, y: data.y })
          }}
          defaultPosition={{ x: rtl ? -openTreeButtonPosition.x : openTreeButtonPosition.x, y: openTreeButtonPosition.y }}
        >
          <Fab color='primary' size='small' style={{ position: 'absolute' }}>
            <TooltipR text="Отобразить шапку страницы">
              {Icon(null, IconHeader, null, "inherit")}
            </TooltipR>
          </Fab>
        </Draggable>
      )}
      <Paper
        square
        elevation={0}
        style={{
          margin: 0,
          overflow: "auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${process.env.PUBLIC_URL}/layout.jpg`,
          backgroundSize: "cover",
        }}
      >
        {SNOWFALL_ENABLED && <CustomSnowfall />}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Splitter
            style={{ padding: 0, width: "100%", flexGrow: 1 }}
            percentage={true}
            primaryMinSize={10}
            secondaryMinSize={15}
            leftShadow
            secondaryInitialSize={20}
          >
            <Splitter
              style={{ padding: 0, flexGrow: 1 }}
              percentage={true}
              primaryMinSize={10}
              secondaryMinSize={10}
              secondaryInitialSize={81}
            >
              <div
                style={{
                  display: isTreeVisible ? "flex" : "none",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Workspace isTreeVisible={isTreeVisible} closeTree={() => setTreeVisibility(false)} />
              </div>
              <TabbedView />
            </Splitter>
            {hiddenColumns && <HiddenColumns />}
            {propertiesData && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Splitter
                  style={{ padding: 0, flexGrow: 1 }}
                  percentage={true}
                  primaryMinSize={10}
                  secondaryMinSize={10}
                  secondaryInitialSize={30}
                >
                  <PropertiesEditor />
                </Splitter>
              </div>
            )}
          </Splitter>
        </div>
      </Paper>
      <Divider />
    </div>
  );
};

export default React.memo(AppPage);
