import React from "react";
import cookie from "react-cookies";
import { useSelector } from "react-redux";

import { ActionDialog, StyledButtonBig } from "shared/ui";
import { fText } from "shared/ui/Editors";

import { ReactComponent as IconLock } from "@mdi/svg/svg/lock-outline.svg";

import { ReactComponent as UserIcon } from "@mdi/svg/svg/account-circle-outline.svg";

function LoginForm({ onClose, isLoading, setLoading, loadUserData }) {
  const api = useSelector((state) => state.API);

  const [value, setValue] = React.useState({
    login: null,
    password: null,
    rememberCredentials: true,
  });

  const handleLogin = React.useCallback(
    ({ login, password, rememberCredentials } = {}) => {
      if (!login || !password) return;
      setLoading(true);

      api.call("user/login", { login, password })
        .then(async (response) => {
          if (!response || !response?.sessionToken) {
            setLoading(false);
            return;
          }

          if (rememberCredentials) {
            localStorage.setItem("session_token", `"${response.sessionToken}"`);
            cookie.save("sessionToken", response.sessionToken, { path: process.env.PUBLIC_URL + "/" });
            cookie.save("login", login, { path: process.env.PUBLIC_URL + "/" });
            cookie.save("password", password, { path: process.env.PUBLIC_URL + "/" });
          }

          loadUserData(response.sessionToken, true);
          onClose();
        })
    },
    [api, onClose, setLoading, loadUserData]
  );

  const handleChange = (v, event) => {
    const i = { ...value };
    i[v] = event.target.value;
    setValue(i);
  };
  const handleClose = () => {
    onClose && onClose();
  };

  const dataProps = {
    disabled: false,
    onChange: handleChange,
    value,
    fullWidth: true,
    style: "compact",
    size: "medium",
  };

  return (
    <ActionDialog
      open
      maxWidth={600}
      onClose={handleClose}
      title={document.title}
    >
      <span style={{ fontSize: "3em", fontWeight: 100, textAlign: "center" }}>
        <span>Добро пожаловать</span>
      </span>
      <div style={{ height: "1em" }} />
      {fText(
        dataProps,
        <span>Логин</span>,
        "login",
        null,
        { type: "text", Icon: UserIcon, autoComplete: "username" },
        "login"
      )}
      {fText(
        dataProps,
        <span>Пароль</span>,
        "password",
        null,
        {
          type: "password",
          Icon: IconLock,
          autoComplete: "current-password",
        },
        "password"
      )}
      {fText(
        dataProps,
        <span>Запомнить данные</span>,
        "rememberCredentials",
        null,
        { type: "switch", size: "small" },
        "remember"
      )}
      <div style={{ height: "1em" }} />
      <StyledButtonBig
        disabled={isLoading}
        size={"large"}
        color={"primary"}
        variant={"contained"}
        onClick={handleLogin.bind(this, value)}
      >
        <span>Войти</span>
      </StyledButtonBig>
    </ActionDialog>
  );
}

export default React.memo(LoginForm);
