import React from "react";
import styled from "styled-components";

import { Checkbox, IconButton as MuiButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import SVG from "react-inlinesvg";

import { Icon, TooltipR, ToolButton } from "shared/ui/ToolBar";

import TableComponent from "shared/ui/TableEditor";
import HtmlEditor from "shared/ui/Editor/HtmlEditor";

import { projectItemIconComponent } from "shared/utils";
import { ActionDialog, ActionInput, ActionAutocompleteInput } from "shared/ui";
import {
  fMemo,
  fText,
  fMultiSelect,
  fNumber,
  fDateTime,
  fDate,
  fBoolean,
  fSelect,
  PhoneField,
} from "shared/ui/Editors";
import { DoubleList } from "shared/multiList/DoubleListDialog";

import { ReactComponent as OpenImageIcon } from "@mdi/svg/svg/image-search-outline.svg";
import { ReactComponent as OpenFileIcon } from "@mdi/svg/svg/inbox-arrow-down.svg";
import { ReactComponent as OffCameraIcon } from "@mdi/svg/svg/camera-off.svg";
import { ReactComponent as UploadIcon } from "@mdi/svg/svg/upload-outline.svg";
import { ReactComponent as ViewIcon } from "@mdi/svg/svg/eye-outline.svg";
import { ReactComponent as CloseIcon } from "@mdi/svg/svg/close.svg";

import TagsList from "./components/TagsList";
import Textarea from "./components/Textarea";

import "./index.css";
import Registry from "components/itemView/Registry";
import { useSnackbar } from "notistack";

const handleUploadClick = (
  api,
  property,
  value,
  handleChange_v2,
  event,
  onError
) => {
  const file = event.target.files[0];
  if (!file) return onError?.();

  const formData = new FormData();
  formData.append("data", file);
  formData.append("token", api?.token);

  fetch(
    (api?.secondServer ||
      api?.opt?.server ||
      "https://localizer.contourbi.ru/model/api") + "/file/upload",
    { method: "POST", body: formData }
  )
    .then((response) => (response.status === 200 ? response.json() : null))
    .then((json) =>
      handleChange_v2(value, property, null, json?.filename, json?.url)
    )
    .catch(() => onError?.());
};

const startInputAdornment = (value, property, size, theme) => {
  if (!value?.[property]) return null;
  const style = {
    margin: "0.75em 0.5em 0.5em 0.5em",
    width: size,
    height: size,
    maxHeight: size,
    maxWidth: size,
    fill: theme.palette.info.main,
  };
  const icon = projectItemIconComponent(value?.[property], style);
  return (
    <InputAdornment
      position="start"
      style={{ height: "auto", maxHeight: "unset" }}
    >
      {icon || <div />}
    </InputAdornment>
  );
};

const endInputAdornment = (
  api,
  value,
  property,
  icon,
  theme,
  handleChange_v2,
  ndx
) => (
  <InputAdornment position="end">
    <input
      color={"primary"}
      accept={"image/*"}
      type={"file"}
      onChange={(event) =>
        handleUploadClick(api, property, value, handleChange_v2, event)
      }
      id={ndx}
      style={{ display: "none" }}
    />
    <label htmlFor={ndx} style={{ margin: 0 }}>
      <TooltipR text={null}>
        <MuiButton size={"small"} component={"span"}>
          {Icon("action", icon)}
        </MuiButton>
      </TooltipR>
    </label>
    <TooltipR text="Clear">
      <MuiButton
        size="small"
        onClick={() => handleChange_v2(value, property, null, null)}
      >
        {Icon("action", CloseIcon)}
      </MuiButton>
    </TooltipR>
  </InputAdornment>
);

const DownloadEndInputAdornment = ({ value, property, name, canUpload, uploadProps, onChange }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = React.useState(false);
  const href = value[property];

  const download = () => {
    const aTag = document.createElement("a");
    aTag.href = href;
    aTag.download = name;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  }

  const upload = () => {
    const el = document.getElementById("file-upload-input");
    if (!el) return;
    el.click();
  }
  
  const remove = async () => {
    const data = new FormData();
    data.append("file", "");
    Object.entries(uploadProps || {}).forEach(([key, value]) => data.append(key, value));

    const res = await fetch((process.env.REACT_APP_API || "https://av.contourbi.ru/api") + "/file_link/upload", {
      method: "POST",
      body: data
    });

    const response = await res.json();
    if (!res.ok || !response || response?.error) return;
    onChange(value, property, null, null);
  }

  const onFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    data.append("alias", file.name);
    Object.entries(uploadProps || {}).forEach(([key, value]) => data.append(key, value));

    fetch((process.env.REACT_APP_API || "https://av.contourbi.ru/api") + "/file_link/upload", {
      method: "POST",
      body: data
    }).then(async (res) => {
      const el = document.getElementById("file-upload-input");
      el.value = "";
      const response = await res.json();
      if (!res.ok || !response || response?.error) throw new Error();
      onChange(value, property, null, response.url);
    }).catch(() => enqueueSnackbar("Ошибка загрузки", { variant: "error" })).finally(() => setLoading(false));
  }

  return (
    <InputAdornment position="end">
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <>
          {!!canUpload && (
            <label style={{ margin: 0 }}>
              <TooltipR text="Удалить">
                <MuiButton onClick={remove} size={"small"} component={"span"}>
                  {Icon("action", CloseIcon)}
                </MuiButton>
              </TooltipR>
              <TooltipR text="Загрузить">
                <MuiButton onClick={upload} size={"small"} component={"span"}>
                  {Icon("action", UploadIcon)}
                </MuiButton>
              </TooltipR>
            </label>
          )}
          <label style={{ margin: 0 }}>
            <TooltipR text="Скачать">
              <MuiButton onClick={download} disabled={!href} size={"small"} component={"span"}>
                {Icon(!href ? "disabled" : "action", OpenFileIcon)}
              </MuiButton>
            </TooltipR>
          </label>
        </>
      )}
      <input onChange={onFileUpload} id="file-upload-input" type="file" style={{ display: "none" }} />
    </InputAdornment>
  );
};

function ImageBlock({ api, value, i, ndx, disabled, handleChange }) {
  const [isViewImageModalOpened, setViewImageModalOpened] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);

  const inputId = "imageBlock" + ndx;

  const openFileInput = () => {
    const input = document.getElementById(inputId);
    input.click();
  };

  const handleImageUploaded = (value, property, event, filename, url) => {
    setImageUrl(url);
    setLoading(false);
    handleChange(value, property, event, filename);
  };

  const handleInputChange = (event) => {
    setLoading(true);
    handleUploadClick(api, i.value, value, handleImageUploaded, event, () =>
      setLoading(false)
    );
  };

  const handleImageRemove = () => {
    const input = document.getElementById(inputId);
    input.value = null;
    setImageUrl(null);
    handleChange(value, i.value, null, null);
  };

  const handleImageView = () => setViewImageModalOpened(true);

  const isImageExist = !!value[i.value];
  const imageSrc = imageUrl || value[i.value];

  return (
    <div>
      {i.label && (
        <div style={{ color: "rgba(0, 0, 0, 0.54)", marginBottom: "4px" }}>
          {i.label}
        </div>
      )}
      <div className="image-block-editor">
        {!disabled && (
          <div className="image-block-editor-toolbar">
            {ToolButton(
              UploadIcon,
              openFileInput,
              true,
              "Upload image",
              1,
              "secondary",
              false,
              "bottom",
              true
            )}
            {ToolButton(
              ViewIcon,
              handleImageView,
              isImageExist,
              "View image",
              2,
              "secondary",
              false,
              "bottom",
              true
            )}
            {ToolButton(
              CloseIcon,
              handleImageRemove,
              isImageExist,
              "Remove image",
              3,
              "secondary",
              false,
              "bottom",
              true
            )}
          </div>
        )}
        {isImageExist ? (
          imageSrc.includes("svg") ? (
            <SVG src={imageSrc} style={{ width: "100%", height: "100%" }} />
          ) : (
            <img
              src={imageSrc}
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
              alt=""
            />
          )
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress color="primary" size="32px" />
            ) : (
              Icon("action", OffCameraIcon, 50)
            )}
          </div>
        )}
      </div>
      <ActionDialog
        open={isViewImageModalOpened}
        onOk={() => setViewImageModalOpened(false)}
        title="View image"
        withAction
      >
        {isImageExist && imageSrc.includes(".svg") ? (
          <SVG src={imageSrc} style={{ width: "100%", height: "100%" }} />
        ) : (
          <img
            src={imageSrc}
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
            alt=""
          />
        )}
      </ActionDialog>
      <input
        accept="image/*"
        id={inputId}
        style={{ display: "none" }}
        type="file"
        onChange={handleInputChange}
      />
    </div>
  );
}

const Container = styled("div")`
  display: flex;
  width: 100%;

  & div:first-child {
    width: 100%;
  }
`;

function SelectWithCheckbox({ dataProps, label, valueProp, data, ndx }) {
  const [disabled, setDisabled] = React.useState(!dataProps.value[valueProp]);
  const handleCheckboxToggle = React.useCallback(() => {
    setDisabled(!disabled);
    dataProps.onChange_v2(
      dataProps.value,
      valueProp,
      undefined,
      !disabled
        ? undefined
        : data.onlyIdInValue
        ? data.select[0]?.id
        : data.select[0]
    );
  }, [disabled, dataProps, valueProp, data]);
  return (
    <Container>
      {fSelect(dataProps, label, valueProp, null, { ...data, disabled }, ndx)}
      <TooltipR text="Включить фильтр"><Checkbox checked={!disabled} onChange={handleCheckboxToggle} /></TooltipR>
    </Container>
  );
}

export const editorComponent = (
  i,
  ndx,
  inputProps,
  dataProps,
  handleChange_v2,
  handleChange_v2_link,
  handleCahnge_v3,
  value,
  schema_query,
  emitChanged,
  theme,
  props,
  api,
  isSingleElementOnTab
) => {
  const label =
    typeof i.label === "function" ? i.label(value, schema_query) : i.label;
  const valueProp =
    !i.value || typeof i.value === "function" || i.value.split(".").length === 1
      ? i.value
      : (getSet, object, value) => {
          if (getSet === "get") {
            const props = i.value.split(".");
            return props.reduce((prev, next, ndx, props) => {
              if (!prev) {
                return undefined;
              }
              if (ndx === props.length - 1) {
                return prev[next];
              } else {
                if (!prev[next]) {
                  // don't create empty object. Mongo not allow it for save
                  return undefined;
                }
                return prev[next];
              }
            }, object);
          } else {
            handleChange_v2(object, i.value, null, value);
          }
        };

  switch (i.type) {
    case "textWithIcon":
      return (
        <ActionInput
          key={ndx}
          {...inputProps}
          label={label}
          startInputAdornment={startInputAdornment(
            value,
            i.data.iconName,
            i.data.size,
            theme
          )}
          endInputAdornment={endInputAdornment(
            value,
            i.data.iconName,
            OpenImageIcon,
            theme,
            handleChange_v2
          )}
          onChange_v2={handleChange_v2}
          value={value}
          v={i.value}
          tooltip={i.tooltip}
          autoFocus={i.autoFocus}
        />
      );
    case "file":
      return (
        <ActionInput
          key={ndx}
          {...inputProps}
          {...i.data}
          label={label}
          endInputAdornment={endInputAdornment(
            api,
            value,
            i.value,
            OpenFileIcon,
            theme,
            handleChange_v2,
            ndx
          )}
          onChange_v2={handleChange_v2}
          value={value}
          v={i.value}
          tooltip={i.tooltip}
          autoFocus={i.autoFocus}
        />
      );
    case "download":
      const getFileValue = () => {
        if (!i.data.hideValue) return value;
        if (!value?.[i.value]) return "";
        const splittedValue = value[i.value].split("download/");
        if (splittedValue.length === 1) return splittedValue;
        return splittedValue[1].split("?filename")[0] || "";
      }
      return (
        <ActionInput
          key={ndx}
          {...inputProps}
          {...i.data}
          label={label}
          endInputAdornment={<DownloadEndInputAdornment value={value} property={i.value} name={getFileValue()} canUpload={i.data.upload} uploadProps={i.data.uploadProps} onChange={handleChange_v2} />}
          v={() => getFileValue()}
          tooltip={i.tooltip}
          autoFocus={i.autoFocus}
        />
      );
    case "phone":
      return (
        <PhoneField dataProps={{ ...dataProps, ...(i.data || {}) }} label={label} prop={valueProp} />
      )
    case "text":
    case "password":
    case "switch":
      return fText(
        dataProps,
        label,
        valueProp,
        null,
        {
          autoFocus: i.autoFocus,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
          type: i.type,
          ...(i.data || {}),
        },
        ndx
      );
    case "textarea":
      const inputValue = dataProps.value[i.value];
      return (
        <Textarea 
          title={i.label}
          value={inputValue}
          disabled={i.data.disabled}
          onChange={dataProps.onChange_v2.bind(null, dataProps.value, i.value)}
        />
      );
    case "Dictionary":
    case "fSelect":
      return fSelect(
        dataProps,
        label,
        valueProp,
        null,
        {
          autoFocus: i.autoFocus,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
          schema: props?.schema,
          type: i.type,
          select: i.data.select || [],
          ...(i.data || {}),
        },
        ndx
      );
    case "fSelectCheckbox":
      return (
        <SelectWithCheckbox
          key={ndx}
          {...{
            dataProps,
            label,
            valueProp,
            data: {
              autoFocus: i.autoFocus,
              variant: inputProps.variant,
              inCell: inputProps.inCell,
              schema: props.schema,
              type: i.type,
              select: i.data.select || [],
              ...(i.data || {}),
            },
            ndx,
          }}
        />
      );
    case "multiSelect":
      return fMultiSelect(
        dataProps,
        label,
        valueProp,
        null,
        {
          autoFocus: i.autoFocus,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
          type: i.type,
          select: i.data.select || [],
          ...(i.data || {}),
        },
        ndx
      );
    case "number":
      return fNumber(
        dataProps,
        label,
        i.value,
        null,
        { autoFocus: i.autoFocus, variant: inputProps.variant },
        ndx
      );
    case "boolean":
      return fBoolean(dataProps, label, i.value, null, i.data || {}, ndx);
    case "DateTime":
      return fDateTime(
        dataProps,
        label,
        i.value,
        null,
        {
          autoFocus: i.autoFocus,
          disabled: i.data?.disabled,
          autoSetValue: i.data?.autoSetValue,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
          ...(i.data || {}) /*type: i.type, ...(i.data || {})*/,
        },
        ndx
      );
    case "Date":
      return fDate(
        dataProps,
        label,
        i.value,
        null,
        {
          autoFocus: i.autoFocus,
          disabled: i.data?.disabled,
          autoSetValue: i.data?.autoSetValue,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
          ...(i.data || {}) /*type: i.type, ...(i.data || {})*/,
        },
        ndx
      );
    case "Memo":
      return fMemo(
        dataProps,
        label,
        i.value,
        null,
        {
          autoFocus: i.autoFocus,
          disableClean: i.data?.disableClean,
          disabled: i.data?.disabled,
          variant: inputProps.variant,
          inCell: inputProps.inCell,
        },
        i.data?.lines,
        ndx
      );
    case "Table":
      return (
        <TableComponent
          key={ndx}
          value={value}
          closeDialog={props?.onClose}
          onChange={handleChange_v2}
          schema={i}
          {...(i?.componentProps || {})}
        />
      );
    case "Registry": 
      function getHeight() {
        if (i.value === "subindicator_2") return { flex: 1 };
        if (i.value === "subindicator_4") return { minHeight: "30vh" };
        if (isSingleElementOnTab) return { flex: 1 };
        return { minHeight: "40vh" };
      }

      return (
        <div style={{ margin: "0.25em 0.25em 0.5em 0.25em", flex: 1, display: "flex", flexDirection: "column" }} key={ndx}>
          <div style={{ fontSize: "0.85em", color: "rgba(0, 0, 0, 0.5)", padding: "0 0.25em 0.25em 1.2em" }}>{i.label}</div>
          <div style={{ display: "flex", width: "100%", ...getHeight(), overflow: "hidden", border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: 4 }}>
            <Registry 
              objectValue={value} 
              subRegistry={i.data?.subRegistry}
              rowData={i.data?.rowData}
              columnStyles={i.data?.styles}
              widgetAction={i.data?.action}
              singleRowSelect={i.data?.singleRowSelect}
              parentRowField={i.data?.parentRowField}
              parentRowCode={i.data?.parentRowCode}
              withOperations={i.data?.withOperations}
              filters={i.data?.filters}
              canEdit={i.data?.editable}
              onChange={handleCahnge_v3}
              code={i.data?.code}
            />
          </div>
        </div>
      );
    case "MultiInput":
      return (
        <div key={ndx} style={{ display: "flex" }}>
          {Array.isArray(i.data) &&
            i.data.map((data, index) =>
              editorComponent(
                data,
                ndx + "-mi-" + index,
                inputProps,
                dataProps,
                handleChange_v2,
                handleChange_v2_link,
                value,
                schema_query,
                emitChanged,
                theme,
                props
              )
            )}
        </div>
      );
    case "imageBlock":
      return (
        <div key={ndx} style={{ display: "flex", gap: 10 }}>
          <ImageBlock
            i={i}
            value={value}
            api={api}
            ndx={ndx}
            disabled={i.data?.disabled}
            handleChange={handleChange_v2}
          />
          <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
            {i.data?.withProps?.map((prop, ndx) =>
              editorComponent(
                prop,
                ndx,
                inputProps,
                dataProps,
                handleChange_v2,
                handleChange_v2_link,
                value,
                schema_query,
                emitChanged,
                theme,
                props
              )
            )}
          </div>
        </div>
      );
    case "multiField":
      return (
        <div key={ndx} style={{ display: "flex", position: "relative", flexDirection: "column", margin: "0.5em 0.25em", padding: "0.75em 0.25em 0.25em", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 4 }}>
          <div style={{ position: "absolute", left: 8, top: -9, fontSize: 12, color: i.disabled ? "rgba(0, 0, 0, 0.55)" : "rgba(0, 0, 0, 0.6)", lineHeight: "16px", padding: "0 4px", background: "white" }}>
          НПА, регламентирующий сбор и формирование данных
          </div>
          {i.fields?.map((prop, ndx) =>
            editorComponent(
              prop,
              ndx,
              inputProps,
              dataProps,
              handleChange_v2,
              handleChange_v2_link,
              value,
              schema_query,
              emitChanged,
              theme,
              props
            )
          )}
        </div>
      );
    case "tagsList":
      return (
        <TagsList
          key={ndx}
          state={value}
          conditions={i.data?.conditions || []}
          tags={i.data?.tags || []}
          value={value[i.value]}
          onChange={(data, code) =>
            handleChange_v2(value, code || i.value, null, data)
          }
        />
      );
    case "Editor":
      return (
        <HtmlEditor
          key={ndx}
          value={value[i.value]}
          onChange={(d) =>
            handleChange_v2(value, i.value, { target: { value: null } }, d)
          }
        />
      );
    case "select":
      return (
        <ActionAutocompleteInput
          disabled={
            (i.rootId && !i.rootId(value, schema_query)) || i.data?.disabled
          }
          key={ndx}
          {...inputProps}
          label={label}
          onChange_v2={handleChange_v2_link}
          value={value}
          v={
            typeof i.value === "function"
              ? i.value.bind(null, schema_query)
              : i.value
          }
          tooltip={i.tooltip}
          autocompleteProps={{
            disableClearable: true,
            api: i.api?.(value, schema_query) || props.api,
            rootId: i.rootId && i.rootId?.(value, schema_query),
            isTree: true,
            ...(i.data?.autocompleteProps || {}),
          }}
        />
      );
    case "doubleList":
      // dataFn - должна вычесляться при каждом обращение
      // например - для списка полей  - меняется запрос
      return (
        schema_query && (
          <DoubleList
            {...(i.data?.doubleListBoxProps || {})}
            {...i.dataFn?.(props, value, schema_query)}
            key={ndx}
            style={{ padding: "1.25em 0.25em 0.25em 0.25em" }}
            setChanged={emitChanged}
          />
        )
      );
    default:
      return null;
  }
};
